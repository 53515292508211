export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = -50;

// FS BANNER
export const MESSAGE_FS_BANNER_TITLE_Y = -231;
export const MESSAGE_FS_BANNER_YOU_WON_Y = -158;
export const MESSAGE_FS_BANNER_FEAT_NAME_Y = -72;
export const MESSAGE_FS_BANNER_FIG_POSITIONS = [-261, -83];
export const MESSAGE_FS_BANNER_DESCRIPTION_Y = 131;
export const MESSAGE_FS_BANNER_PRESS_Y = 214;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = -25;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 93;
