/* eslint-disable import/no-dynamic-require */

/* eslint-disable @typescript-eslint/no-var-requires */

/* eslint-disable global-require */
import { Resources } from '@phoenix7dev/shared-components';

import resources from '../assets/resources.json';
import { ResourceTypes } from '../resources.d';

const cachedResources = new Resources<ResourceTypes>(resources);
cachedResources.initialize((value: string): string => {
  return require(`../assets/${value}`);
});

export default cachedResources;
