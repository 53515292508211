import { makeVar } from '@apollo/client';

import AudioHowl from '@phoenix7dev/play-music';
import { getUserConfig } from '@phoenix7dev/utils-fe';

import {
  BonusStatus,
  FreeRoundBonus,
  GameMode,
  IBonus,
  ISettledBet,
  ModalOpeningTypes,
  UserBonus,
  reelSets,
} from '../global.d';

import { IBet, IConfig, ISlotConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsLeftHandMode = makeVar<boolean>(getUserConfig<IConfig>('config', 'isLeftHandMode', false));

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsShowSoundToast = makeVar<boolean>(AudioHowl.isRestricted);

export const setIsSuspended = makeVar<boolean>(false);

export const setSoundValue = makeVar<number>(Number(setIsSoundOn()));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setPrevReelSetId = makeVar<string>('');

export const setPrevDiaScore = makeVar<number[] | undefined>([20, 20, 20, 20, 20]);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(25);

export const setCurrency = makeVar<string>('FUN');

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeatureGame = makeVar<string>('');

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinAmount = makeVar<number>(1);

export const setCoinValue = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.REGULAR);

export const setReelSetId = makeVar<string>('');

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  data: {
    features: {
      diamonds: [],
    },
  },
});

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  bonusId: '',
  currentRound: 0,
  totalRounds: 0,
  rounds: 4,
  reelSetId: reelSets[GameMode.REGULAR],
  totalWinAmount: 0,
  coinAmount: 1,
  coinValue: 1,
  betId: '',
  bonus: {
    id: '',
    slotId: '',
    reelSetId: '',
    lineSetId: '',
    type: '',
    coinAmount: 1,
    purchasable: false,
    rounds: 0,
    data: {},
  },
  status: BonusStatus.INACTIVE,
  data: {
    legend: [],
    multipliers: { columns: [1, 1, 1, 1, 1], rows: [1, 1, 1] },
    rewardAmount: 0,
  },
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  winLines: [],
  settings: {
    startPosition: [],
  },
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setIsMiniPayTable = makeVar<boolean>(getUserConfig<IConfig>('config', 'isMiniPayTable', true));

export const setIsEnabledSpaceSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isEnabledSpaceSpin', true));

export const setSkipIntroScreen = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSkipIntroScreen', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenMenuModal = makeVar<{
  isOpen: boolean;
  type: 'menu' | 'info';
}>({
  isOpen: false,
  type: 'menu',
});
export const setIsOpenBetSettingsModal = makeVar<boolean>(false);
export const setIsOpenAutoplayModal = makeVar<boolean>(false);
export const setIsModalOpeningInProgress = makeVar<ModalOpeningTypes>(ModalOpeningTypes.NONE);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsOpenedMessageBanner = makeVar<boolean>(false);
export const setIsInTransition = makeVar<boolean>(false);

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsBuyFeatureSpin = makeVar<boolean>(false);

export const setIsDuringCollectAnimation = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar(false);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);

export const setReplayBet = makeVar<string>('');

export const setReplayFreeSpinBets = makeVar<string[]>([]);

export const setReplayFreeSpinReelSetId = makeVar<string>('');

export const setFreeRoundBonus = makeVar<FreeRoundBonus>({
  id: '',
  isActive: false,
  coinAmount: 1,
  coinValue: 1,
  bonusId: '',
  //roundsPlayed: 0,
  status: BonusStatus.INACTIVE,
  currentRound: 0,
  rounds: 1,
  totalWinAmount: 0,
});
export const setIsFreeRoundBonus = makeVar<boolean>(false);
export const setIsPopupFreeRoundsOpened = makeVar<boolean>(false);
export const setFreeRoundsTotalWin = makeVar<number>(0);
