import * as PIXI from 'pixi.js';
import { ITextStyle } from 'pixi.js';

// const sample = new PIXI.TextStyle({
//   align: 'center', // string;
//   breakWords: true, // ##false, // boolean;
//   dropShadow: true, // ##false, // boolean;
//   dropShadowAlpha: 0.75, // ##1, // number;
//   dropShadowAngle: 0.785, // ##Math.PI / 6, // number;
//   dropShadowBlur: 10, // ##0, // number;
//   dropShadowColor: 0x000000, // ##'black', // string | number;
//   dropShadowDistance: 4, // number;
//   fill: [0xffc000, 0xff0000], // ##'black', // string | string[] | number | number[] | CanvasGradient | CanvasPattern;
//   fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL, // number;
//   // fillGradientStops: [0,1], //number[];
//   fontFamily: 'NotoSans-SemiCondensedBold', // string | string[];
//   fontSize: 80, // number | string;
//   fontStyle: 'normal', // string;
//   fontVariant: 'normal', // string;
//   fontWeight: 'bold', // ## 'normal', // string;
//   leading: 0, // number;
//   letterSpacing: 0, // number;
//   // lineHeight: 0, //number;
//   lineJoin: 'round', // ##'miter', // string;
//   miterLimit: 10, // number;
//   padding: 4, // ##0, // number;
//   stroke: '#ffffff', // ##'black', // string | number;
//   strokeThickness: 6, // ##0, // number;
//   trim: false, // boolean;
//   textBaseline: 'alphabetic', // string;
//   whiteSpace: 'pre', // string;
//   wordWrap: false, // boolean;
//   wordWrapWidth: 1330, // number;
// });

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  fill: [0xfbc41c, 0xf5ff3c, 0xffffff, 0xff8737, 0xf9ff11, 0xfbc41c, 0xf08418, 0x9f6518, 0xffeb19],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 2,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 7,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 8,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableNumberStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
  trim: true,
};

export const miniPayTableTextStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xf6e87e',
  trim: true,
};

export const bottomContainerTextStyle: Partial<ITextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  fontWeight: 'bold',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winMessage);
