import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  XT001S_BGM_BG_Base = 'XT001S_BGM_BG_Base',
  XT001S_BigWin_End = 'XT001S_BigWin_End',
  XT001S_Anticipation_01 = 'XT001S_Anticipation_01',
  XT001S_Anticipation_02 = 'XT001S_Anticipation_02',
  XT001S_Anticipation_03 = 'XT001S_Anticipation_03',
  XT001S_Anticipation_SP = 'XT001S_Anticipation_SP',
  XT001S_BGM_FS2_Loop = 'XT001S_BGM_FS2_Loop',
  XT001S_BigWin_Loop = 'XT001S_BigWin_Loop',
  XT001S_BGM_FS_Loop = 'XT001S_BGM_FS_Loop',
  XT001S_BigWin_Start = 'XT001S_BigWin_Start',
  XT001S_Scatter_01 = 'XT001S_Scatter_01',
  XT001S_Scatter_02 = 'XT001S_Scatter_02',
  XT001S_Scatter_03 = 'XT001S_Scatter_03',
  XT001S_SpinStart = 'XT001S_SpinStart',
  XT001S_SpinStart_Loop = 'XT001S_SpinStart_Loop',
  XT001S_SpinStop1 = 'XT001S_SpinStop1',
  XT001S_SpinStop2 = 'XT001S_SpinStop2',
  XT001S_SpinStop3 = 'XT001S_SpinStop3',
  XT001S_SpinStop4 = 'XT001S_SpinStop4',
  XT001S_SpinStop5 = 'XT001S_SpinStop5',
  XT001S_Win_Big = 'XT001S_Win_Big',
  XT001S_Win_End = 'XT001S_Win_End',
  XT001S_Win_Epic = 'XT001S_Win_Epic',
  XT001S_Win_Great = 'XT001S_Win_Great',
  XT001S_Win_Loop = 'XT001S_Win_Loop',
  XT001S_Win_Mega = 'XT001S_Win_Mega',
  XT001S_Bingo = 'XT001S_Bingo',
  XT001S_FS_Blank_Landing = 'XT001S_FS_Blank_Landing',
  XT001S_FS_Dia_Landing = 'XT001S_FS_Dia_Landing',
  XT001S_FeatureTrigger = 'XT001S_FeatureTrigger',
  XT001S_LongSpin = 'XT001S_LongSpin',
  XT001S_Openning = 'XT001S_Openning',
  XT001S_TotalWinBanner = 'XT001S_TotalWinBanner',
  XT001S_panel_1 = 'XT001S_panel_1',
  XT001S_panel_2 = 'XT001S_panel_2',
  XT001S_twinkling_1 = 'XT001S_twinkling_1',
  XT001S_twinkling_2 = 'XT001S_twinkling_2',
  XT001S_BGM_DiaCount = 'XT001S_BGM_DiaCount',
  XT001S_DiaCount = 'XT001S_DiaCount',
  XT001S_SpinStart_2 = 'XT001S_SpinStart_2',
  XT001S_TotalWinBanner_Big = 'XT001S_TotalWinBanner_Big',
  XT001S_PushBuyFeature = 'XT001S_PushBuyFeature',
}

export const audioSprite: AudioSprite = {
  [ISongs.SFX_UI_AutoSpin]: [0, 320.4761904761905],
  [ISongs.SFX_UI_BetChange]: [2000, 32.086167800453595],
  [ISongs.SFX_UI_Close]: [4000, 200.6802721088432],
  [ISongs.SFX_UI_General]: [6000, 88.34467120181432],
  [ISongs.SFX_UI_Hover]: [8000, 151.76870748299365],
  [ISongs.SFX_UI_MaxBet]: [10000, 122.92517006802761],
  [ISongs.SFX_UI_MenuOpen]: [12000, 277.14285714285757],
  [ISongs.SFX_UI_SpinStart]: [14000, 249.59183673469454],
  [ISongs.SFX_UI_SpinStop]: [16000, 151.02040816326578],
  [ISongs.XT001S_Anticipation_01]: [18000, 1668.3446712018153],
  [ISongs.XT001S_Anticipation_02]: [21000, 1668.3446712018153],
  [ISongs.XT001S_Anticipation_03]: [24000, 2000],
  [ISongs.XT001S_Anticipation_SP]: [27000, 2000],
  [ISongs.XT001S_BGM_BG_Base]: [30000, 39069.77324263039, true],
  [ISongs.XT001S_BGM_DiaCount]: [71000, 7200.02267573696, true],
  [ISongs.XT001S_BGM_FS2_Loop]: [80000, 8000, true],
  [ISongs.XT001S_BGM_FS_Loop]: [89000, 28001.51927437642, true],
  [ISongs.XT001S_BigWin_End]: [119000, 3428.594104308388],
  [ISongs.XT001S_BigWin_Loop]: [124000, 13714.308390022667, true],
  [ISongs.XT001S_BigWin_Start]: [139000, 1714.3083900226657],
  [ISongs.XT001S_Bingo]: [142000, 1999.9092970521417],
  [ISongs.XT001S_DiaCount]: [145000, 1500],
  [ISongs.XT001S_FS_Blank_Landing]: [148000, 199.99999999998863],
  [ISongs.XT001S_FS_Dia_Landing]: [150000, 2000],
  [ISongs.XT001S_FeatureTrigger]: [153000, 5000],
  [ISongs.XT001S_LongSpin]: [159000, 4000.0453514739147],
  [ISongs.XT001S_Openning]: [165000, 4186.054421768717],
  [ISongs.XT001S_PushBuyFeature]: [171000, 633.3333333333258],
  [ISongs.XT001S_Scatter_01]: [173000, 1000],
  [ISongs.XT001S_Scatter_02]: [175000, 1000],
  [ISongs.XT001S_Scatter_03]: [177000, 1000],
  [ISongs.XT001S_SpinStart]: [179000, 500],
  [ISongs.XT001S_SpinStart_2]: [181000, 1000],
  [ISongs.XT001S_SpinStart_Loop]: [183000, 199.99999999998863, true],
  [ISongs.XT001S_SpinStop1]: [185000, 500],
  [ISongs.XT001S_SpinStop2]: [187000, 500],
  [ISongs.XT001S_SpinStop3]: [189000, 500],
  [ISongs.XT001S_SpinStop4]: [191000, 500],
  [ISongs.XT001S_SpinStop5]: [193000, 500],
  [ISongs.XT001S_TotalWinBanner]: [195000, 4500.022675736972],
  [ISongs.XT001S_TotalWinBanner_Big]: [201000, 7200.02267573696],
  [ISongs.XT001S_Win_Big]: [210000, 1800.0453514739263],
  [ISongs.XT001S_Win_End]: [213000, 1000],
  [ISongs.XT001S_Win_Epic]: [215000, 3000.0453514739147],
  [ISongs.XT001S_Win_Great]: [220000, 1800.0453514739263],
  [ISongs.XT001S_Win_Loop]: [223000, 1000, true],
  [ISongs.XT001S_Win_Mega]: [225000, 1800.0453514739263],
  [ISongs.XT001S_panel_1]: [228000, 1000.0226757369717],
  [ISongs.XT001S_panel_2]: [231000, 1000.0453514739149],
  [ISongs.XT001S_twinkling_1]: [234000, 2000],
  [ISongs.XT001S_twinkling_2]: [237000, 3000],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.XT001S_Anticipation_01]: 0.3,
  [ISongs.XT001S_Anticipation_02]: 0.4,
  [ISongs.XT001S_Anticipation_03]: 0.5,
  [ISongs.XT001S_Anticipation_SP]: 0.5,
  [ISongs.XT001S_BGM_BG_Base]: 0.3,
  [ISongs.XT001S_BGM_FS2_Loop]: 0.3,
  [ISongs.XT001S_BGM_FS_Loop]: 0.3,
  [ISongs.XT001S_BigWin_End]: 0.3,
  [ISongs.XT001S_BigWin_Loop]: 0.3,
  [ISongs.XT001S_BigWin_Start]: 0.3,
  [ISongs.XT001S_Bingo]: 0.3,
  [ISongs.XT001S_FS_Blank_Landing]: 0.3,
  [ISongs.XT001S_FS_Dia_Landing]: 0.3,
  [ISongs.XT001S_FeatureTrigger]: 0.3,
  [ISongs.XT001S_LongSpin]: 0.3,
  [ISongs.XT001S_Openning]: 0.3,
  [ISongs.XT001S_Scatter_01]: 0.3,
  [ISongs.XT001S_Scatter_02]: 0.3,
  [ISongs.XT001S_Scatter_03]: 0.3,
  [ISongs.XT001S_SpinStart]: 0.3,
  [ISongs.XT001S_SpinStart_Loop]: 0.3,
  [ISongs.XT001S_SpinStop1]: 0.3,
  [ISongs.XT001S_SpinStop2]: 0.3,
  [ISongs.XT001S_SpinStop3]: 0.3,
  [ISongs.XT001S_SpinStop4]: 0.3,
  [ISongs.XT001S_SpinStop5]: 0.3,
  [ISongs.XT001S_TotalWinBanner]: 0.3,
  [ISongs.XT001S_Win_Big]: 0.3,
  [ISongs.XT001S_Win_End]: 0.3,
  [ISongs.XT001S_Win_Epic]: 0.3,
  [ISongs.XT001S_Win_Great]: 0.3,
  [ISongs.XT001S_Win_Loop]: 0.3,
  [ISongs.XT001S_Win_Mega]: 0.3,
  [ISongs.XT001S_panel_1]: 0.3,
  [ISongs.XT001S_panel_2]: 0.3,
  [ISongs.XT001S_twinkling_1]: 0.3,
  [ISongs.XT001S_twinkling_2]: 0.3,
  [ISongs.XT001S_BGM_DiaCount]: 0.3,
  [ISongs.XT001S_DiaCount]: 0.3,
  [ISongs.XT001S_SpinStart_2]: 0.3,
  [ISongs.XT001S_TotalWinBanner_Big]: 0.3,
  [ISongs.XT001S_PushBuyFeature]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
