import { BetBonusReward, BetReward, BonusStatus, ISettledBet, UserBonus, freeRoundBonusId } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';

export const isGetFreeSpinBonus = (nextResult: ISettledBet) => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

  const freeSpinBonus = nextResult?.rewards.filter(isBonusReward).find((reward) => {
    return reward.userBonus?.bonus.type === 'FREE_SPIN';
  })?.userBonus;

  return freeSpinBonus ? true : false;
};

export const getFreeRoundBonus = (nextResult: ISettledBet) => {
  return nextResult.bet.data.bonuses.find((e) => e.isFreeBet);
};

export const getUserActiveBonuses = async () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};
