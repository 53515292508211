import { TrackEntry } from '@pixi-spine/runtime-3.8';
import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Texture, Ticker } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../../config';

export class MultiplierPanel extends Container<Sprite | Spine> {
  private sprite: Sprite;

  private spine: Spine;

  private mp: number;

  private animName: 'center' | 'side';

  private texName: 'Bottom' | 'Side';

  constructor(type: 'center' | 'side') {
    super();

    this.animName = type;
    this.texName = type === 'center' ? 'Bottom' : 'Side';
    this.pivot.x = type === 'center' ? 0 : -175;

    this.mp = 100;
    this.sprite = new Sprite();
    this.sprite.anchor.set(0.5);
    this.spine = new Spine(Loader.shared.resources.Free_count.spineData!);
    this.spine.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation.name === `ber_${this.animName}_rot`) {
          Ticker.shared.addOnce(() => {
            this.loop();
          });
        }
      },
    });

    this.addChild(this.sprite, this.spine);
  }

  public init(mp: number): void {
    this.mp = mp;
    this.sprite.texture = Texture.from(`free${this.texName}MpX${mp}`);
    this.sprite.visible = false;
    if (mp < 10) {
      this.spine.skeleton.setSkinByName(`bai_0${mp}`);
    } else {
      this.spine.skeleton.setSkinByName(`bai_${mp}`);
    }
    this.spine.state.setEmptyAnimations(0);
  }

  public loop(): void {
    this.sprite.visible = true;
    if (this.mp >= 10) {
      if (this.animName === 'center') {
        this.spine.state.setAnimation(0, 'efx_center', true);
      } else {
        this.spine.state.setAnimation(0, this.mp === 100 ? 'efx_side_B' : 'efx_side_A', true);
      }
    } else {
      this.spine.state.setEmptyAnimations(0);
    }
  }

  public bingo(): void {
    if (this.animName === 'center') {
      this.spine.state.setAnimation(1, 'lineY', true);
    } else {
      if (this.mp < 10) {
        this.spine.state.setAnimation(1, 'lineT_A', true);
      } else if (this.mp < 100) {
        this.spine.state.setAnimation(1, 'lineT_B', true);
      } else {
        this.spine.state.setAnimation(1, 'lineT_C', true);
      }
    }
  }

  public reveal(): void {
    this.sprite.visible = false;
    this.spine.state.setAnimation(0, `ber_${this.animName}_rot`, false);
    this.spine.state.addEmptyAnimation(0, 0, 0);

    AudioHowl.play({
      type: this.mp >= 10 ? ISongs.XT001S_panel_2 : ISongs.XT001S_panel_1,
      stopPrev: true,
    });
  }
}
