import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { isBaseGameMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import ReelsFrame from '../reels/frame/reelsFrame';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public announceContainer: PIXI.Container;

  public linesContainer: PIXI.Container;

  public frame: ReelsFrame;

  constructor(props: IGameContainer) {
    super();
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.announceContainer = props.announceContainer;
    this.linesContainer = props.linesContainer;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        (SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT) / 2,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.frame = new ReelsFrame();
    this.addChild(this.frame);
    this.slotStopDisplayContainer.addChild(this.linesContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.announceContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameTitle);

    const gameReplay = new GameReplay();
    this.addChild(gameReplay);

    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    this.visible = isBaseGameMode(settings.mode);
  }

  private resize(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
