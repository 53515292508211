import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import ViewContainer from '../../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, SLOT_WIDTH, eventManager } from '../../config';

class ReelsFrame extends ViewContainer {
  private frame: Spine;

  private reachFrame: Spine;

  constructor() {
    super();
    this.frame = this.initFrame();
    this.reachFrame = this.initReachFrame();

    this.addChild(this.frame, this.reachFrame);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {}

  private initFrame(): Spine {
    const frame = new Spine(PIXI.Loader.shared.resources.Base_frame.spineData!);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    frame.state.setAnimation(0, 'loop', true);
    return frame;
  }

  private initReachFrame(): Spine {
    const frame = new Spine(PIXI.Loader.shared.resources.Base_reach_frame.spineData!);
    frame.position.set(SLOT_WIDTH * 4.5, SLOTS_CONTAINER_HEIGHT / 2);
    frame.visible = false;
    return frame;
  }

  private onAnticipationAnimationStarts(): void {
    this.reachFrame.visible = true;
    this.reachFrame.state.setAnimation(0, 'in', false);
    this.reachFrame.state.addAnimation(0, 'loop', true, 0);
  }

  private onAnticipationAnimationEnd(): void {
    this.reachFrame.state.setAnimation(0, 'out', false);
  }

  private onStartSpin(isTurboSpin?: boolean): void {
    this.reachFrame.visible = false;
  }
}

export default ReelsFrame;
