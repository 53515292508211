import { upgradeConfig } from '@pixi/particle-emitter';
import { IPointData, Loader } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { ParticleEmitterContainer } from '../../components/particles';
import { eventManager } from '../../config';

import { sparkConfig } from './sparkConfig';

export class Track extends ParticleEmitterContainer {
  private animation?: AnimationGroup;

  constructor() {
    super(upgradeConfig(sparkConfig, Loader.shared.resources.Result.spineAtlas!.findRegion('efx_star_00000').texture));
    this.stop(true);

    eventManager.on(EventTypes.START_TRACK_ANIMATION, this.startTrackAnimation.bind(this));
    eventManager.on(EventTypes.SKIP_COLLECT_ANIMATION, () => {
      const anim = this.animation;
      this.animation = undefined;
      anim?.skip();
      this.stop(true);
    });
  }

  private startTrackAnimation(start: IPointData, end: IPointData, duration: number, cb?: () => void) {
    const xTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.X,
      propertyBeginValue: start.x,
      target: end.x,
      duration,
    });
    const yTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.Y,
      propertyBeginValue: start.y,
      target: end.y,
      duration,
    });
    const anim = new AnimationGroup();
    this.animation = anim;
    anim.addAnimation(xTween);
    anim.addAnimation(yTween);
    anim.addOnStart(() => {
      this.stop(true);
      this.start();
    });
    anim.addOnComplete(() => {
      if (this.animation) {
        this.stop();
        cb?.();
      }
    });
    anim.start();
  }
}
