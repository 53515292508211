import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 6;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const FEATURE_POPUP_BET_TEXT_POSITION = [0, -139];
export const FEATURE_POPUP_BET_VALUE_POSITION_X = 0;
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = -70;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = -143;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 194;
export const FEATURE_POPUP_INPUT_POSITION_X = 0;
export const FEATURE_POPUP_INPUT_POSITION_Y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = -162;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = -67;
export const FEATURE_POPUP_MP_BTN_HITAREA_SCALE = 1.8;
export const FEATURE_POPUP_OK_BTN_POSITION_X = 159;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = -FEATURE_POPUP_MINUS_BTN_POSITION_X;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
export const FEATURE_POPUP_POSITION_X = SLOTS_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_POSITION_Y = SLOTS_CONTAINER_HEIGHT / 2;
export const FEATURE_POPUP_TITLE_POSITION_X = 0;
export const FEATURE_POPUP_TITLE_POSITION_Y = -225;
export const FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_Y = 95;
export const FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_Y = 22;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = -136;
export const FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y = -82;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = 6;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = 75;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION = [
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
];
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION = [FEATURE_POPUP_OK_BTN_POSITION_X, FEATURE_POPUP_OK_BTN_POSITION_Y];
