import { type ITextStyle } from 'pixi.js';

import { AutoResizeTextStyle } from '../text/autoResizeText';

export type BingoLine = 'lineT01' | 'lineT02' | 'lineT03' | 'lineT04' | 'lineT05' | 'lineY01' | 'lineY02' | 'lineY03';

export const bingoLineIndex: Record<BingoLine, number> = {
  lineT01: 0,
  lineT02: 1,
  lineT03: 2,
  lineT04: 3,
  lineT05: 4,
  lineY01: 5,
  lineY02: 6,
  lineY03: 7,
};

export const bingoLineList: Array<{ line: BingoLine; pos: number[] }> = [
  { line: 'lineT01', pos: [0, 5, 10] },
  { line: 'lineT02', pos: [1, 6, 11] },
  { line: 'lineT03', pos: [2, 7, 12] },
  { line: 'lineT04', pos: [3, 8, 13] },
  { line: 'lineT05', pos: [4, 9, 14] },
  { line: 'lineY01', pos: [0, 1, 2, 3, 4] },
  { line: 'lineY02', pos: [5, 6, 7, 8, 9] },
  { line: 'lineY03', pos: [10, 11, 12, 13, 14] },
];

export const bottomMultiplierPosition = [
  [-573, 312],
  [-287, 312],
  [0, 312],
  [287, 312],
  [573, 312],
];

export const sideMultiplierPosition = [
  [628, -340],
  [652, -132],
  [678, 92],
];

export type SymbolSetting = {
  scale: number;
  position: [number, number];
};

export const symbolSettings: SymbolSetting[] = [
  { scale: 0.9, position: [-529, -324] },
  { scale: 0.9, position: [-264.5, -324] },
  { scale: 0.9, position: [0, -324] },
  { scale: 0.9, position: [264.5, -324] },
  { scale: 0.9, position: [529, -324] },
  { scale: 0.95, position: [-545, -115.5] },
  { scale: 0.95, position: [-272.5, -115.5] },
  { scale: 0.95, position: [0, -115.5] },
  { scale: 0.95, position: [272.5, -115.5] },
  { scale: 0.95, position: [545, -115.5] },
  { scale: 1.0, position: [-565, 108] },
  { scale: 1.0, position: [-282.5, 108] },
  { scale: 1.0, position: [0, 108] },
  { scale: 1.0, position: [282.5, 108] },
  { scale: 1.0, position: [565, 108] },
];

export const bottomTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#ffffff',
  fontWeight: 'bold',
  fontSize: 80,
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
  maxHeight: 130,
  maxWidth: 1150,
};

export const bottomTotalTextStyle: Partial<AutoResizeTextStyle> = {
  ...bottomTextStyle,
  fontSize: 70,
  fill: '#ffff10',
  maxHeight: 85,
};

export const bottomSubTextStyle: Partial<AutoResizeTextStyle> = {
  ...bottomTextStyle,
  fontSize: 50,
  maxHeight: 65,
};

export const rateTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#ffffff',
  fontWeight: 'bold',
  fontSize: 45,
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
  maxWidth: 290,
};
