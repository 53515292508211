import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Loader } from 'pixi.js';

import { MessageWinBannerProps } from '../../global.d';
import { setBetAmount } from '../../gql/cache';
import i18n from '../../i18next';
import { BASE_WIN_AMOUNT_LIMIT, BIG_WIN_AMOUNT_LIMIT, GREAT_WIN_AMOUNT_LIMIT, MEGA_WIN_AMOUNT_LIMIT } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { MESSAGE_WIN_BANNER_TITLE_Y, MESSAGE_WIN_BANNER_WIN_AMOUNT_Y } from './config';
import { totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected props: MessageWinBannerProps;

  private totalWin?: PIXI.Text;

  private spine: Spine;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.scale.set(1 / 0.9);
    this.spine = new Spine(Loader.shared.resources.Result.spineData!);
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.3;
    backdrop.width = 5000;
    backdrop.height = 5000;
    this.addChild(backdrop);
  }

  public init(): MessageWinBanner {
    super.init();

    const animName = this.getAnimName(this.props.totalWinAmount);
    this.totalWin = this.initWinTotal(this.props.totalWin);

    if (animName) {
      this.banner!.visible = false;
      this.addChild(this.spine);
      this.spine.skeleton.setSkinByName(animName);
      this.spine.state.setAnimation(0, 'wind_big', true);
      this.spine.hackTextureBySlotName('wind_big_L22', this.totalWin.texture, this.totalWin.texture.frame);
    } else {
      this.banner?.scale.set(0.66);
      const title = this.initTitle();
      this.addChild(title, this.totalWin);
    }

    return this;
  }

  protected initTitle(): PIXI.Text {
    const winTitle = new PIXI.Text(i18n.t<string>('freeSpinsTotalWin.Title'), totalWinTitleStyles);
    winTitle.anchor.set(0.5);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y - 40;
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.updateText(true);
    winTotal.anchor.set(0.5);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y - 40;
    return winTotal;
  }

  private getAnimName(win: number): string | undefined {
    const multiplier = win / setBetAmount();

    if (multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return undefined;
    } else if (multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return 'text_01';
    } else if (multiplier < MEGA_WIN_AMOUNT_LIMIT) {
      return 'text_02';
    } else if (multiplier < GREAT_WIN_AMOUNT_LIMIT) {
      return 'text_03';
    } else {
      return 'text_04';
    }
  }
}
