import { TextMetrics } from 'pixi.js';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Input } from '@phoenix7dev/shared-components';
import { PropsInput } from '@phoenix7dev/shared-components/dist/input/d';

import styles from './autoPlaySettings.module.scss';
import { inputSidePadding, inputTextStyle } from './config';

export const AutoPlaySettingsInput: React.FC<Omit<PropsInput, 'onChange' | 'readOnly' | 'type' | 'className'>> = ({
  value,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const calcFontSize = useCallback((text: string, boxWidth: number) => {
    const metrics = TextMetrics.measureText(text, inputTextStyle);
    if (boxWidth >= metrics.width) {
      return Number(inputTextStyle.fontSize);
    }

    return Math.floor(Number(inputTextStyle.fontSize) * (boxWidth / metrics.width));
  }, []);

  useEffect(() => {
    if (ref.current) {
      const fontSize = calcFontSize(`${value}`, ref.current.clientWidth - inputSidePadding);

      ref.current.style.fontSize = `${fontSize}px`;
      ref.current.style.fontWeight = inputTextStyle.fontWeight;
    }
  }, [value, calcFontSize]);

  return (
    <Input className={`${styles.input}`} type="text" value={value} onChange={() => {}} readOnly ref={ref} {...props} />
  );
};
