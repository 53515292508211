import { Container, Sprite, Text, Texture } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { setBetAmount, setCurrency } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import { countCoins, formatNumber, normalizeCoins, showCurrency } from '../../../utils';
import { eventManager } from '../../config';
import AutoResizeText from '../../text/autoResizeText';
import { rateTextStyle } from '../config';

export class RatePanel extends Container {
  private text: Text;

  constructor() {
    super();
    this.position.set(-626, -488);

    const base = new Sprite(Texture.from(ResourceTypes.freePartsImgRate));
    base.anchor.set(0.5);
    base.scale.set(0.8);

    this.text = new AutoResizeText('', rateTextStyle);
    this.text.anchor.set(0.5);
    this.onUpdateBet();

    this.addChild(base, this.text);

    eventManager.on(EventTypes.INITIALIZE_FREESPINS, this.onUpdateBet.bind(this));
  }

  private onUpdateBet(): void {
    this.text.text = `1 = ${formatNumber(
      setCurrency(),
      normalizeCoins(countCoins({ totalAmount: setBetAmount() })),
      showCurrency(setCurrency()),
    )}`;
  }
}
