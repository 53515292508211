export const parseUUID = (uuid: string): Uint8Array => {
  let v: number;
  const arr = new Uint8Array(16);

  // Parse ########-....-....-....-............
  arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
  arr[1] = (v >>> 16) & 0xff;
  arr[2] = (v >>> 8) & 0xff;
  arr[3] = v & 0xff;

  // Parse ........-####-....-....-............
  arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
  arr[5] = v & 0xff;

  // Parse ........-....-####-....-............
  arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
  arr[7] = v & 0xff;

  // Parse ........-....-....-####-............
  arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
  arr[9] = v & 0xff;

  // Parse ........-....-....-....-############
  // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
  arr[10] = ((v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000) & 0xff;
  arr[11] = (v / 0x100000000) & 0xff;
  arr[12] = (v >>> 24) & 0xff;
  arr[13] = (v >>> 16) & 0xff;
  arr[14] = (v >>> 8) & 0xff;
  arr[15] = v & 0xff;

  // return new DataView(arr.buffer, 0).getUint32(0, true);
  return arr;
};

type RandomIndexType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export const getRandomFromUUID = (uuid: string, max: number, idx: RandomIndexType = 0): number => {
  const uuidArray = parseUUID(uuid);
  let rand = 0;

  if (max <= 256) {
    rand = uuidArray[idx] / 256;
  } else if (max <= 65536) {
    rand = new DataView(uuidArray.buffer).getUint16(idx) / 65536;
  } else {
    throw new Error('rand max is too much');
  }
  return Math.trunc(rand * max);
};

export const getResultFromTbl = (tbl: number[], rand: number): number => {
  let lotsum = 0;
  for (const [index, num] of tbl.entries()) {
    lotsum += num;
    if (rand <= lotsum) {
      return index;
    }
  }
  throw new Error('lottery error, rand is over by lot table sum');
};
