export const CAROUSEL_CONTROLS_COLOR = 0x2e2e2e;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#2e2e2e';
export const CAROUSEL_DOTS_ACTIVE_COLOR = '#ffd200';
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 24;
export const CAROUSEL_DOTS_GAP = 10;
export const CAROUSEL_ARROWS_SIZE = 24;
export const CAROUSEL_ANIMATION_DURATION = 500;
export const CAROUSEL_TEXTBOTTOM_ADJUST_Y = [0, 10, 10];
export const CAROUSEL_TEXTTOP_Y = [0, 75, 75];
export const CAROUSEL_IMAGE_Y = [67, 126, 126];
