export enum ResourceTypes {
  bonusFrame = 'bonusFrame',
  buyFeatureBtnACancel = 'buyFeatureBtnACancel',
  buyFeatureBtnACancelClick = 'buyFeatureBtnACancelClick',
  buyFeatureBtnACancelDisable = 'buyFeatureBtnACancelDisable',
  buyFeatureBtnACancelOver = 'buyFeatureBtnACancelOver',
  buyFeatureBtnAOk = 'buyFeatureBtnAOk',
  buyFeatureBtnAOkClick = 'buyFeatureBtnAOkClick',
  buyFeatureBtnAOkDisable = 'buyFeatureBtnAOkDisable',
  buyFeatureBtnAOkOver = 'buyFeatureBtnAOkOver',
  buyFeatureBtnBM = 'buyFeatureBtnBM',
  buyFeatureBtnBMClick = 'buyFeatureBtnBMClick',
  buyFeatureBtnBMDisable = 'buyFeatureBtnBMDisable',
  buyFeatureBtnBMOver = 'buyFeatureBtnBMOver',
  buyFeatureBtnBP = 'buyFeatureBtnBP',
  buyFeatureBtnBPClick = 'buyFeatureBtnBPClick',
  buyFeatureBtnBPDisable = 'buyFeatureBtnBPDisable',
  buyFeatureBtnBPOver = 'buyFeatureBtnBPOver',
  buyFeatureMainBtn = 'buyFeatureMainBtn',
  buyFeatureMainBtnClick = 'buyFeatureMainBtnClick',
  buyFeatureMainBtnDisable = 'buyFeatureMainBtnDisable',
  buyFeatureMainBtnOver = 'buyFeatureMainBtnOver',
  buyFeatureWakuA = 'buyFeatureWakuA',
  buyFeatureWindow = 'buyFeatureWindow',
  buyFeatureWindowBlack = 'buyFeatureWindowBlack',
  buyFeatureWindowConfirm = 'buyFeatureWindowConfirm',
  messageBanner = 'messageBanner',
  freeBottomMpX10 = 'freeBottomMpX10',
  freeBottomMpX100 = 'freeBottomMpX100',
  freeBottomMpX2 = 'freeBottomMpX2',
  freeBottomMpX20 = 'freeBottomMpX20',
  freeBottomMpX3 = 'freeBottomMpX3',
  freeBottomMpX30 = 'freeBottomMpX30',
  freeBottomMpX5 = 'freeBottomMpX5',
  freeBottomMpX50 = 'freeBottomMpX50',
  freePartsImgRate = 'freePartsImgRate',
  freePartsImgSpinframe = 'freePartsImgSpinframe',
  freeSideMpX10 = 'freeSideMpX10',
  freeSideMpX100 = 'freeSideMpX100',
  freeSideMpX2 = 'freeSideMpX2',
  freeSideMpX20 = 'freeSideMpX20',
  freeSideMpX3 = 'freeSideMpX3',
  freeSideMpX30 = 'freeSideMpX30',
  freeSideMpX5 = 'freeSideMpX5',
  freeSideMpX50 = 'freeSideMpX50',
  introBg = 'introBg',
  introPict01 = 'introPict01',
  introPict02 = 'introPict02',
  introPict03 = 'introPict03',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurSymbolA = 'blurSymbolA',
  blurSymbolB = 'blurSymbolB',
  blurSymbolC = 'blurSymbolC',
  blurSymbolD = 'blurSymbolD',
  blurSymbolE = 'blurSymbolE',
  blurSymbolF = 'blurSymbolF',
  blurSymbolG = 'blurSymbolG',
  blurSymbolH = 'blurSymbolH',
  blurSymbolSC = 'blurSymbolSC',
  blurSymbolSCBuyfeature = 'blurSymbolSCBuyfeature',
  blurSymbolWL = 'blurSymbolWL',
  freeSymbolSC = 'freeSymbolSC',
  freeSymbolBrank = 'freeSymbolBrank',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolC = 'symbolC',
  symbolD = 'symbolD',
  symbolE = 'symbolE',
  symbolF = 'symbolF',
  symbolG = 'symbolG',
  symbolH = 'symbolH',
  symbolSC = 'symbolSC',
  symbolWL = 'symbolWL',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  textLogo = 'textLogo',
}
