import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.symbolSC || slug === ResourceTypes.symbolWL;
};

const calcMultiplier = (multiplier: number, betAmount: number, slug?: ResourceTypes): number => {
  // if (slug === ResourceTypes.scatter)
  //   return (
  //     countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) *
  //     multiplier
  //   );
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const baseData: IPaytableData[] = config.payTableData;

  const { currency } = balance;

  return (
    <section>
      <div className={styles['paytable-list']}>
        {baseData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles.img}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              {hasTranslate(slug) && (
                <div className={styles['paytable-list__title']}>
                  <p
                    className={styles.p}
                    dangerouslySetInnerHTML={{
                      __html: t(`infoPayTable.${slug}`),
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.content}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles.multiplier}>{i.pattern} </span>
                  {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
