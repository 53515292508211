import _ from 'lodash';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, ReelStopSndType, SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setNextResult, setPrevDiaScore } from '../../gql/cache';
import { createScatterScoreArray } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  WIN_ANIMATION_INTERVAL,
  WIN_SLOT_TINT_COLOR,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';
import LinesContainer from '../lines/lineContainer';
import { IAnimateSlot, animateSlotFactory } from '../slot/animateSlot';
import { ScatterAnimateSlot, isScatterAnimateSlot } from '../slot/animateSlot/scatterAnimateSlot';

export class SlotsAnimationContainer extends ViewContainer {
  private slotSymbols: IAnimateSlot[] = [];

  constructor() {
    super();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.cleanSymbols_StartSpin.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.setupSymbols.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult, scatterScores) => {
      this.animation?.skip();
      this.animation = undefined;
      this.initSymbols(spinResult, scatterScores);
      this.slotSymbols.forEach((slot) => (slot.visible = true));
    });
    eventManager.addListener(EventTypes.START_DIA_WIN_ANIMATION, this.onStartDiaWinAnimation.bind(this));
  }

  private initSymbols(spinResult: Icon[], scatterNumbers?: number[]) {
    this.cleanSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = animateSlotFactory(
          spinResult[i * REELS_AMOUNT + j].id,
          scatterNumbers != undefined ? scatterNumbers[j] : 20,
        );
        symbol.x = REEL_WIDTH * j + SLOT_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private setupSymbols(): void {
    const scatterNums = createScatterScoreArray(setNextResult()!.bet.data.features.diamonds);
    setPrevDiaScore(scatterNums);
    this.initSymbols(setNextResult()!.bet.result.spinResult, scatterNums);
  }

  private cleanSymbols(): void {
    this.slotSymbols.forEach((symbol, index) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private cleanSymbols_StartSpin(isTurboSpin?: boolean): void {
    this.slotSymbols.forEach((symbol, index) => {
      if (isScatterAnimateSlot(symbol)) {
        const animation = symbol.getStartSpinAnimation(index, isTurboSpin);
        animation?.addOnSkip(() => {
          this.removeChild(symbol);
        });
        animation?.addOnComplete(() => {
          this.removeChild(symbol);
        });
        animation?.start();
      } else {
        this.removeChild(symbol);
      }
    });
  }

  // Stop
  private onReelStopped(reelId: number, stopSoundSymbolNo: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelId];
      slot.visible = true;

      if (stopSoundSymbolNo >= ReelStopSndType.Scatter1 && isScatterAnimateSlot(slot)) {
        slot.startReachStopAnimation();
      } else {
        slot.startStopAnimation();
      }
    }

    //big anim end
    if (stopSoundSymbolNo === ReelStopSndType.Normal && (reelId === 2 || reelId === 4)) {
      this.slotSymbols.forEach((slot) => {
        if (isScatterAnimateSlot(slot)) slot.endReachStopAnimation();
      });
    }
  }

  // Anticipation
  private onAnticipationAnimationStarts(): void {
    this.slotSymbols.forEach((slot) => {
      if (slot.slotId !== SlotId.SC) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  // Winning
  private skipWinSlotsAnimation(): void {
    this.animation?.skip();
    this.slotSymbols.forEach((slot) => slot.skip());
    this.resetAllSlotTint();
  }

  private onStartWinAnimation(nextResult: ISettledBet): void {
    this.showWin(nextResult);
  }

  private animation?: AnimationChain;

  private showWin(nextResult: ISettledBet): void {
    const { paylines } = nextResult;
    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
    });

    // all line
    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(Array.from(set));
    const allSlotsHighlight2 = this.highlightSlots(Array.from(set));

    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
      this.setWinSlotTint(Array.from(set));
    });
    allSlotsHighlight2.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.resetAllSlotTint();
    });

    this.animation.appendAnimation(allSlotsHighlight);
    this.animation.appendAnimation(allSlotsHighlight2);

    const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
    this.animation.appendAnimation(eachSlotsHighlight);
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((slotPos) => {
      if (!isScatterAnimateSlot(this.slotSymbols[slotPos])) {
        animationGroup.addAnimation(this.slotSymbols[slotPos].getWinAnimation());
      }
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);

      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        this.setWinSlotTint(payline.winPositions);
      });

      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
        this.resetAllSlotTint();
      });
      animationChain.appendAnimation(chain);
    });
    animationChain.appendAnimation(Tween.createDelayAnimation(WIN_ANIMATION_INTERVAL));
    return animationChain;
  }

  private resetAllSlotTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = WIN_SLOT_TINT_COLOR;
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot].tint = 0xffffff;
    });
  }

  private onStartDiaWinAnimation(): void {
    this.animation = new AnimationChain();
    const scatterAnim = new AnimationGroup();
    const delayAnimation = Tween.createDelayAnimation(700);

    this.animation.appendAnimation(delayAnimation);
    this.slotSymbols.forEach((slot) => {
      if (isScatterAnimateSlot(slot)) {
        scatterAnim.addAnimation(slot.getWinAnimation());
      }
    });
    scatterAnim.addOnStart(() => {
      AudioHowl.play({ type: ISongs.XT001S_FeatureTrigger, stopPrev: true });
    });
    scatterAnim.addOnComplete(() => {
      this.animation = undefined;
    });
    this.animation.appendAnimation(scatterAnim);
    this.animation.start();
  }
}
