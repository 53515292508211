export const bgParticleConfig = {
  alpha: {
    list: [
      {
        value: 0,
        time: 0,
      },
      {
        value: 1,
        time: 0.3,
      },
      {
        value: 1,
        time: 0.7,
      },
      {
        value: 0,
        time: 1,
      },
    ],
    isStepped: false,
  },
  scale: {
    list: [
      {
        value: 0.8,
        time: 0,
      },
    ],
    isStepped: false,
    minimumScaleMultiplier: 0.5,
  },
  color: {
    list: [
      {
        value: '#ffffff',
        time: 0,
      },
    ],
    isStepped: false,
  },
  speed: {
    list: [
      {
        value: 30,
        time: 0,
      },
    ],
    isStepped: false,
    minimumSpeedMultiplier: 0.5,
  },
  acceleration: {
    x: 0,
    y: 0,
  },
  maxSpeed: 10,
  startRotation: {
    min: 270,
    max: 270,
  },
  noRotation: false,
  rotationSpeed: {
    min: 30,
    max: 50,
  },
  lifetime: {
    min: 3.5,
    max: 4,
  },
  blendMode: 'add',
  frequency: 0.1,
  emitterLifetime: -1,
  maxParticles: 200,
  pos: {
    x: 0,
    y: 0,
  },
  addAtBack: false,
  spawnType: 'rect',
  spawnRect: {
    x: -1100,
    y: -650,
    w: 2200,
    h: 1300,
  },
};
