import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../../config';
import Animation from '../../animations/animation';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export class SpineAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private sprite: PIXI.Sprite;

  private spine: Spine;

  private duringAnimFlg: boolean;

  constructor(slotId: SlotId) {
    super();

    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);

    this.sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.visible = true;

    this.spine = new Spine(PIXI.Loader.shared.resources[`${MAPPED_SYMBOLS[slotId]}_Animation`].spineData!);
    this.spine.visible = false;

    this.addChild(this.sprite, this.spine);

    this.duringAnimFlg = false;

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(
          () => {
            if (!this.duringAnimFlg) {
              this.idle = true;
            }
          },
          this,
          PIXI.UPDATE_PRIORITY.HIGH,
        );
      },
    });
  }

  public startStopAnimation(): void {
    this.idle = false;
    this.spine.state.setAnimation(0, 'Stop', false);
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.zIndex = 5;
  }

  public startWinAnimation(): void {
    this.idle = false;
    this.spine.state.setAnimation(0, 'Win', false);
    // this.spine.state.addEmptyAnimation(0, 0, 0);
    this.zIndex = 10;
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation('Win')?.duration ?? 0.9) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = 10;
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.duringAnimFlg = true;
        this.spine.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      this.idle = true;
      this.duringAnimFlg = false;
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.duringAnimFlg = false;
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {
    this.idle = true;
    this.duringAnimFlg = false;
    this.spine.skeleton.setToSetupPose();
  }

  public set idle(value: boolean) {
    this.sprite.visible = value;
    this.spine.visible = !value;
    this.zIndex = value ? 0 : 10;
  }

  public get idle(): boolean {
    return this.sprite.visible;
  }

  public set tint(value: number) {
    this.spine.tint = value;
    this.sprite.tint = value;
  }
}
