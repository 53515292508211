import { SlotId } from '../../../config';

import { IAnimateSlot } from './IAnimateSlot';
import { ScaleAnimateSlot } from './scaleAnimateSlot';
import { ScatterAnimateSlot } from './scatterAnimateSlot';
import { SpineAnimateSlot } from './spineAnimateSlot';
import { WildAnimateSlot } from './wildAnimateSlot';

export function animateSlotFactory(slotId: SlotId, num?: number): IAnimateSlot {
  // TODO
  if (num == undefined) num = 0;

  switch (slotId) {
    case SlotId.WL:
      return new WildAnimateSlot(slotId);
    case SlotId.SC:
      return new ScatterAnimateSlot(slotId, num);
    default:
      return new SpineAnimateSlot(slotId);
  }
}
