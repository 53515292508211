import { ColorOverlayFilter } from 'pixi-filters';
import { Container, Sprite, Texture } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { setBetAmount, setCurrency, setCurrentFreeSpinsTotalWin, setReplayBet } from '../../../gql/cache';
import i18n from '../../../i18next';
import { ResourceTypes } from '../../../resources.d';
import { countCoins, formatNumber, normalizeCoins, showCurrency } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { eventManager } from '../../config';
import AutoResizeText from '../../text/autoResizeText';
import { bottomSubTextStyle, bottomTextStyle, bottomTotalTextStyle } from '../config';

export class BottomTextPanel extends Container {
  private subText: AutoResizeText;

  private text: AutoResizeText;

  private filter: ColorOverlayFilter;

  constructor() {
    super();

    this.position.set(0, 1080 / 2 - 38 - 64 + 16);

    this.filter = new ColorOverlayFilter(0xffffff, 0);
    this.filters = [];

    const base = new Sprite(Texture.from(ResourceTypes.freePartsImgSpinframe));
    base.anchor.set(0.5);
    base.scale.y = 1.3;

    this.text = new AutoResizeText(i18n.t<string>('freeSpinsBottom.remain', { count: 3 }), bottomTextStyle);
    this.text.anchor.set(0.5);

    this.subText = new AutoResizeText('', bottomSubTextStyle);
    this.subText.y = -60;
    this.subText.anchor.set(0.5, 0);

    this.addChild(base, this.subText, this.text);

    eventManager.on(EventTypes.RESET_FREESPINS_REMAIN, () => {
      this.setRemain(3);
    });

    eventManager.on(EventTypes.SET_FREESPINS_RESULT, this.setResultText.bind(this));
  }

  public setRemain(count: number) {
    this.filters = [];
    this.text.text = i18n.t<string>('freeSpinsBottom.remain', { count });
    this.text.style = bottomTextStyle;
    this.text.anchor.y = 0.5;
    this.text.y = 0;
    this.subText.text = '';
  }

  private setResultText(pay: number, mult: number, total: number) {
    this.filters = [];

    const updateText = () => {
      this.subText.text =
        pay > 0
          ? i18n.t<string>('freeSpinsBottom.collectSub', {
              pay,
              count: mult,
            })
          : '';
      this.text.text = i18n.t<string>('freeSpinsBottom.collectTotal', {
        total: formatNumber(
          setCurrency(),
          normalizeCoins(countCoins({ totalAmount: setBetAmount() * total })),
          showCurrency(setCurrency()),
        ),
      });
      this.text.style = bottomTotalTextStyle;
      this.text.anchor.y = 1;
      this.text.y = 70;
      if (setReplayBet()) {
        setCurrentFreeSpinsTotalWin(setBetAmount() * total);
      }
    };
    if (pay > 0) {
      this.blink(updateText);
    } else {
      updateText();
    }
  }

  private blink(cb: () => void): void {
    const chain = new AnimationChain({});
    chain.appendAnimation(
      new Tween({
        object: this.filter,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 0,
        target: 0.9,
        duration: 100,
      }),
    );
    const fadeOut = new Tween({
      object: this.filter,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.9,
      target: 0,
      duration: 100,
    });
    fadeOut.addOnStart(cb);
    chain.appendAnimation(fadeOut);
    chain.addOnStart(() => {
      this.filters = [this.filter];
    });
    chain.addOnComplete(() => {
      this.filters = [];
    });
    chain.start();
  }
}
