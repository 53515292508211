import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.symbolWL,
      combos: [],
    },
    {
      slug: ResourceTypes.symbolSC,
      combos: [],
    },
    {
      slug: ResourceTypes.symbolA,
      combos: [
        { pattern: 'x5', multiplier: 600 },
        { pattern: 'x4', multiplier: 150 },
        { pattern: 'x3', multiplier: 25 },
      ],
    },
    {
      slug: ResourceTypes.symbolB,
      combos: [
        { pattern: 'x5', multiplier: 400 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.symbolC,
      combos: [
        { pattern: 'x5', multiplier: 300 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 15 },
      ],
    },
    {
      slug: ResourceTypes.symbolD,
      combos: [
        { pattern: 'x5', multiplier: 300 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 15 },
      ],
    },
    {
      slug: ResourceTypes.symbolE,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolF,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolG,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolH,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  WL = 'WL',
  SC = 'SC1',
}

export enum ReelStopSndType {
  Normal,
  Scatter1,
  Scatter2,
  Scatter3,
}

export const SC_REELS = [0, 2, 4];
export const ANTICIPATION_START_REELID = 3;
export const ANTICIPATION_START_SCCNT = 2;
