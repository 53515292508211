import _, { map } from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setBetAmount, setCurrency, setGameMode, setIsDuringBigWinLoop } from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { formatNumber, isFreeSpinsMode, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE_SCALE,
  DOUBLE_WIN_AMOUNT_LIMIT,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  EPIC_WIN_TITLE_SCALE,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_TITLE_SCALE,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_TITLE_SCALE,
  MINIMUM_FRACTION_DIGITS,
  WINCOUNTUP_TEXT_POS_X,
  WINCOUNTUP_TEXT_POS_Y,
  WIN_CHECK_TIME_DURATION_ON_FREE_SPIN,
  WinStages,
  eventManager,
  winValueStyles,
} from '../config';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle = new PIXI.Text(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    winValueStyles,
  );

  constructor() {
    super();
    this.winTitle.x = WINCOUNTUP_TEXT_POS_X;
    this.winTitle.y = WINCOUNTUP_TEXT_POS_Y;
    this.winTitle.anchor.set(0.5, 0.5);
    this.winTitle.visible = false;
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));
  }

  private hideWinCountUpMessage(): void {
    this.winTitle.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(nextResult: ISettledBet): void {
    this.createWinAnimation(nextResult).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  public createWinAnimation(nextResult: ISettledBet): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(nextResult.bet.result.winCoinAmount);
    const stage = this.countStage(betAmount, winAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    if (stage <= WinStages.BaseWin) {
      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      baseWinAnimation.addOnStart(() => {
        AudioHowl.play({ type: ISongs.XT001S_Win_Loop, stopPrev: true });
      });
      baseWinAnimation.addOnComplete(() => {
        AudioHowl.stop({ type: ISongs.XT001S_Win_Loop });
        AudioHowl.play({ type: ISongs.XT001S_Win_End, stopPrev: true });
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        AudioHowl.stop({ type: ISongs.XT001S_Win_Loop });
        AudioHowl.play({ type: ISongs.XT001S_Win_End, stopPrev: true });
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      animationChain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_COINS);
      });
      animationChain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      if (stage >= WinStages.BigWin) {
        const bigWinAnimationGroup = new AnimationGroup();
        const bigWinAnimation = this.createBigWinAnimation(winAmount, betAmount, stage);

        bigWinAnimation.addOnStart(() => {
          AudioHowl.play({
            type: ISongs.XT001S_BigWin_Start,
            stopPrev: true,
            event: {
              soundKey: ISongs.XT001S_BigWin_Start,
              type: 'end',
              callback: () => {
                AudioHowl.play({
                  type: ISongs.XT001S_BigWin_Loop,
                  stopPrev: true,
                });
              },
            },
          });
        });

        bigWinAnimationGroup.addAnimation(bigWinAnimation);
        animationChain.appendAnimation(bigWinAnimationGroup);
      }
      if (stage >= WinStages.MegaWin) {
        const megaWinAnimation = this.createMegaWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(megaWinAnimation);
      }
      if (stage >= WinStages.GreatWin) {
        const greatWinAnimation = this.createGreatWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(greatWinAnimation);
      }
      if (stage >= WinStages.EpicWin) {
        const epicWinAnimation = this.createEpicWinAnimation(winAmount, betAmount);
        animationChain.appendAnimation(epicWinAnimation);
      }
    }
    if (stage > 1) {
      const bigWinEndDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.XT001S_BigWin_End].duration);
      bigWinEndDelay.addOnStart(() => {
        setIsDuringBigWinLoop(false);
        AudioHowl.stop({ type: ISongs.XT001S_BigWin_Start });
        AudioHowl.stop({ type: ISongs.XT001S_BigWin_Loop });
        AudioHowl.play({ type: ISongs.XT001S_BigWin_End });
      });
      bigWinEndDelay.addOnSkip(() => {
        AudioHowl.stop({ type: ISongs.XT001S_BigWin_End });
      });
      bigWinEndDelay.addOnComplete(() => {
        AudioHowl.stop({ type: ISongs.XT001S_BigWin_End });
      });
      animationChain.appendAnimation(bigWinEndDelay);
    } else {
      const winEndDelay = Tween.createDelayAnimation(WIN_CHECK_TIME_DURATION_ON_FREE_SPIN);
      animationChain.appendAnimation(winEndDelay);
    }
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winTitle,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: BIG_WIN_END_DURATION,
    });

    fadeOutAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      eventManager.emit(EventTypes.COUNT_UP_END);
    });
    fadeOutAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      if (winAmount > 0) {
        this.winTitle.alpha = 1;
        this.winTitle.visible = true;
      }
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private clean(): void {
    setIsDuringBigWinLoop(false);
    AudioHowl.stop({ type: ISongs.XT001S_BigWin_Loop });
    AudioHowl.stop({ type: ISongs.XT001S_BigWin_End });
    AudioHowl.stop({ type: ISongs.XT001S_Win_Epic });
    AudioHowl.stop({ type: ISongs.XT001S_Win_Great });
    AudioHowl.stop({ type: ISongs.XT001S_Win_Mega });
    AudioHowl.stop({ type: ISongs.XT001S_Win_Big });
    BgmControl.fadeInBase(3000);

    this.winTitle.visible = false;
    this.winTitle.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createBigWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const bigWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    bigWinAnimation.addOnStart(() => {
      if (AudioHowl.isPlaying(ISongs.XT001S_Openning)) {
        AudioHowl.fadeOut(1000, ISongs.XT001S_Openning);
      }
      BgmControl.fadeOutAll(1000);
      AudioHowl.play({ type: ISongs.XT001S_Win_Big, stopPrev: true });
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, true);
    });
    bigWinAnimation.addOnSkip(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Big });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    bigWinAnimation.addOnComplete(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Big });
      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    bigWinAnimation.appendAnimation(countUpAnimationGroup);
    return bigWinAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const megaWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    megaWinAnimation.addOnStart(() => {
      AudioHowl.play({ type: ISongs.XT001S_Win_Mega, stopPrev: true });
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, true);
    });
    megaWinAnimation.addOnSkip(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Mega });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });
    megaWinAnimation.addOnComplete(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Mega });
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });

    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    megaWinAnimation.appendAnimation(countUpAnimationGroup);
    return megaWinAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const greatWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    greatWinAnimation.addOnStart(() => {
      AudioHowl.play({ type: ISongs.XT001S_Win_Great, stopPrev: true });
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, true);
    });
    greatWinAnimation.addOnSkip(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Great });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    greatWinAnimation.addOnComplete(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Great });
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    greatWinAnimation.appendAnimation(countUpAnimationGroup);
    return greatWinAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number): Animation {
    const epicWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    epicWinAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, true);
      AudioHowl.play({ type: ISongs.XT001S_Win_Epic });
    });
    epicWinAnimation.addOnComplete(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Epic });
    });
    epicWinAnimation.addOnSkip(() => {
      AudioHowl.stop({ type: ISongs.XT001S_Win_Epic });
      this.setWinValue(win);
    });
    const duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    epicWinAnimation.appendAnimation(countUpAnimationGroup);

    return epicWinAnimation;
  }

  private countStage(bet: number, win: number): WinStages {
    const multiplier = win / bet;

    if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
      return WinStages.None;
    }
    if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinStages.BaseWin;
    }
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinStages.BigWin;
    }
    if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
    if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
    return WinStages.EpicWin;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber(setCurrency(), winValue, showCurrency(setCurrency()))}`;
  }
}

export default WinCountUpMessage;
