import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, IAuthInput } from '../../global.d';
import {
  setBetAmount,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setSkipIntroScreen,
  setStressful,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { formatNumber, normalizeCoins, parseQuery, queryParams, showCurrency } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const progress = useReactiveVar(setProgress);
  const { i18n } = useTranslation();

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  const handleIdle = useCallback(
    (state: SlotMachineState) => {
      if (state !== SlotMachineState.IDLE) return;
      const replayFreeSpins = setReplayFreeSpinBets();
      if (!replayFreeSpins.length && !setCurrentBonus().isActive) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
      }
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    console.log('mount');
    eventManager.on(EventTypes.POST_RENDER, () => {
      if (setReplayBet()) {
        eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
        window.setTimeout(() => {
          const replayFreeSpins = setReplayFreeSpinBets();
          if (replayFreeSpins.length) {
            const triggerSpin = replayFreeSpins.shift();
            setReplayBet(triggerSpin);
          }
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          if (setCurrentBonus().isActive && setCurrentBonus().betId) {
            eventManager.removeAllListeners(EventTypes.END_FREESPINS);
            eventManager.on(EventTypes.END_FREESPINS, () => {
              setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
              setLastRegularWinAmount(setFreeSpinsTotalWin());
              eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
                totalWin: `${formatNumber(
                  setCurrency(),
                  normalizeCoins(setFreeSpinsTotalWin()),
                  showCurrency(setCurrency()),
                )} `,
                totalWinAmount: setFreeSpinsTotalWin(),
                preventDefaultDestroy: true,
                title: i18n.t('freeSpinsTotalWin.result', {
                  betAmount: formatNumber(setCurrency(), normalizeCoins(setBetAmount()), showCurrency(setCurrency())),
                  totalAmount: setCurrentBonus().data.rewardAmount,
                }),
              });
              setCurrentBonus({ ...setCurrentBonus(), isActive: false });
              handleIdle(SlotMachineState.IDLE);
            });
          } else {
            eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
              handleIdle(state);
            });
          }
        }, 0);
      }
    });
  }, [handleIdle, i18n]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
