import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../../config';
import Animation from '../../animations/animation';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export class WildAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private spine: Spine;

  constructor(slotId: SlotId) {
    super();

    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);

    this.spine = new Spine(PIXI.Loader.shared.resources[`${MAPPED_SYMBOLS[slotId]}_Animation`].spineData!);
    this.spine.visible = true;
    this.spine.state.setAnimation(0, 'Loop', true);

    this.addChild(this.spine);

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {}, this, PIXI.UPDATE_PRIORITY.HIGH);
      },
    });
  }

  public startStopAnimation(): void {
    this.spine.state.setAnimation(0, 'Stop', false);
    this.spine.state.addAnimation(0, 'Loop', true, 0);
    this.zIndex = 5;
  }

  public startWinAnimation(): void {
    this.spine.state.setAnimation(0, 'Win', false);
    this.spine.state.addAnimation(0, 'Loop', true, 0);
    this.zIndex = 10;
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation('Win')?.duration ?? 0) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = 10;
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      //TO DO, change to get animation name
      if (!(this.spine.state.tracks[0].loop === true)) {
        this.spine.state.setAnimation(0, 'Loop', true);
      }
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {}

  public set tint(value: number) {
    this.spine.tint = value;
  }
}
