import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { SpriteButton } from '../components/button';
import { eventManager } from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
} from './config';
import {
  buyFeatureConfirmTextStyle,
  buyFeatureConfirmTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

export class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private popupBlackBoard: PIXI.Sprite;

  private popupConfirmBg: PIXI.Sprite;

  private titleText: AutoResizeText;

  private confirmText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostValue: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  constructor() {
    super();
    this.x = FEATURE_POPUP_POSITION_X;
    this.y = FEATURE_POPUP_POSITION_Y;
    this.visible = false;
    this.popupBg = this.initPopupBg();
    this.popupBlackBoard = this.initPopupBlackBoard();
    this.popupConfirmBg = this.initPopupConfirmBg();
    this.titleText = this.initTitle();
    this.confirmText = this.initConfirmText();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.popupBlackBoard);
    this.addChild(this.popupConfirmBg);
    this.addChild(this.titleText);
    this.addChild(this.confirmText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose(true));
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose(true));
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose(true);
    });
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureWindow));
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initPopupBlackBoard(): PIXI.Sprite {
    const popupBlackBoard = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureWindowBlack));
    popupBlackBoard.anchor.set(0.5);

    return popupBlackBoard;
  }

  private initPopupConfirmBg(): PIXI.Sprite {
    const popupConfirmBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureWindowConfirm));
    popupConfirmBg.anchor.set(0.5);

    return popupConfirmBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t<string>('buyFeature.confirm.title'), buyFeatureConfirmTitleStyle);
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;

    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initConfirmText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t<string>('buyFeature.confirm.text'), buyFeatureConfirmTextStyle);
    text.y = FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y;

    text.anchor.set(0.5, 0.5);

    return text;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t<string>('buyFeature.totalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;

    totalCostText.anchor.set(0.5, 0.5);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(0.5, 0.5);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnACancel,
        hover: ResourceTypes.buyFeatureBtnACancelOver,
        press: ResourceTypes.buyFeatureBtnACancelClick,
        disable: ResourceTypes.buyFeatureBtnACancelDisable,
      },
      () => this.handleClose(false),
    );
    cancelBtn.position.set(...FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnAOk,
        hover: ResourceTypes.buyFeatureBtnAOkOver,
        press: ResourceTypes.buyFeatureBtnAOkClick,
        disable: ResourceTypes.buyFeatureBtnAOkDisable,
      },
      () => {
        this.okBtn.interactive = false;
        this.cancelBtn.interactive = false;
        AudioHowl.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS);
        setIsBuyFeaturePurchased(true);
      },
    );
    okBtn.position.set(...FEATURE_POPUP_CONFIRM_OK_BTN_POSITION);
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  public handleOpen(totalCost: string, coinAmount: number): void {
    this.visible = true;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
    this.totalCostValue.text = totalCost;

    this.okBtn.disable = false;
    this.cancelBtn.disable = false;
  }

  public handleClose(skipBuyFeatureOpen?: boolean): void {
    this.visible = false;
    if (!skipBuyFeatureOpen) {
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioHowl.play({ type: ISongs.SFX_UI_Close });
    }
  }
}
