import * as PIXI from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { setReelSetId } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isBuyFeatureMode } from '../../utils';
import { REEL_WIDTH, SLOT_HEIGHT, SLOT_SCALE } from '../config';

export class Slot extends PIXI.Sprite {
  private id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.textureSlotId = slotId;
    this.id = id;
    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);
    this.anchor.set(0.5, 0.5);
    this.pivot.y = -SLOT_HEIGHT / 2;
    this.x = REEL_WIDTH / 2;
    this.y = SLOT_HEIGHT * id;

    if (slotId === SlotId.SC) {
      this.zIndex = 10;
    }
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.texture = PIXI.Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId]);
      if (this.slotId == SlotId.SC) {
        if (isBuyFeatureMode()) {
          this.texture = PIXI.Texture.from(ResourceTypes.blurSymbolSCBuyfeature);
        } else {
          this.scale.set(SLOT_SCALE);
          this.anchor.set(0.5, (473 + 200) / 800);
        }
      }
    } else {
      this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
      this.scale.set(SLOT_SCALE);
      this.anchor.set(0.5, 0.5);
    }
  }

  public onSlotStopped(): void {
    // on slot stopped
  }
}
