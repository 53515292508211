import { Container } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import AnimationChain from '../../animations/animationChain';
import Tween from '../../animations/tween';
import { eventManager } from '../../config';
import { BingoLine, bingoLineIndex, bottomMultiplierPosition, sideMultiplierPosition } from '../config';

import { MultiplierPanel } from './mpPanel';

export class MultiplierPanels extends Container<MultiplierPanel> {
  constructor() {
    super();

    for (let i = 0; i < 5; i++) {
      const multiplier = new MultiplierPanel('center');
      multiplier.position.set(...bottomMultiplierPosition[i]);
      this.addChild(multiplier);
    }

    for (let i = 0; i < 3; i++) {
      const multiplier = new MultiplierPanel('side');
      multiplier.position.set(...sideMultiplierPosition[i]);
      this.addChild(multiplier);
    }

    eventManager.addListener(EventTypes.GET_BINGO, this.bingo.bind(this));
  }

  public init(mp_array: number[]): void {
    if (mp_array.length < this.children.length) return;
    this.children.forEach((mp, i) => mp.init(mp_array[i]));
  }

  public loop(): void {
    this.children.forEach((mp) => mp.loop());
  }

  public reveal(): void {
    const anim = new AnimationChain();
    this.children.forEach((mp, i) => {
      const delay = Tween.createDelayAnimation(500);
      delay.addOnComplete(() => mp.reveal());
      anim.appendAnimation(delay);
    });
    anim.appendAnimation(Tween.createDelayAnimation(1000));
    anim.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'spin');
    });
    anim.start();
  }

  private bingo(line: BingoLine) {
    this.children[bingoLineIndex[line]]?.bingo();
  }
}
