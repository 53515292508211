import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import {
  ANTICIPATION_START_REELID,
  ANTICIPATION_START_SCCNT,
  ISongs,
  mappedAudioSprites,
  ReelStopSndType,
  SC_REELS,
  SlotId,
} from '../config';
import {
  BetBonusReward,
  BetReward,
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  setBetAmount,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setNextResult,
  setPrevDiaScore,
  setPrevReelSetId,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import {
  getUserBonuses,
  getUserGql,
  isStoppedGql,
  ReelSetType,
  slotBetGql,
} from '../gql/query';
import {
  createScatterScoreArray,
  formatNumber,
  getGameModeByBonusId,
  getSpinResult3x5,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinsMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import { AnnouceContainer } from './announce/announceContainer';
import {
  LOTS_SCATTER_ANNOUNCE_DENOM,
  LOTTBL_SC_ANNOUNCE,
} from './announce/config';
import { getRandomFromUUID, getResultFromTbl } from './announce/utils';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { BuyFeaturePopup } from './buyFeature/buyFeaturePopup';
import { BuyFeaturePopupConfirm } from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  eventManager,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  JINGLE_TO_WIN_DURATION,
  REELS_AMOUNT,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import { FreeSpinsView } from './freeSpinsView';
import GameView from './gameView/gameView';
import LinesContainer from './lines/lineContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsAnimationContainer } from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotConfig,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotAnimationContainer: SlotsAnimationContainer;

  public announceContainer: AnnouceContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public linesContainer: LinesContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public buyFeatureBtn?: BuyFeatureBtn;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;
    const scatterScores = setUserLastBetResult().id
      ? createScatterScoreArray(setUserLastBetResult().data.features.diamonds)
      : undefined;

    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));
    setPrevDiaScore(scatterScores);
    setPrevReelSetId(setUserLastBetResult().reelSetId);

    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
      : slotConfig.reels.find(
          (reelSet) => reelSet.type === ReelSetType.DEFAULT,
        )!;
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult3x5({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    this.slotAnimationContainer = new SlotsAnimationContainer();
    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      spinResult,
      scatterScores,
    );

    this.announceContainer = new AnnouceContainer(spinResult);

    this.miniPayTableContainer = new MiniPayTableContainer(
      slotConfig.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.linesContainer = new LinesContainer(slotConfig.winLines);

    this.background = new Background();
    this.application.stage.addChild(this.background);

    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      announceContainer: this.announceContainer,
      slotStopDisplayContainer: this.slotAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      linesContainer: this.linesContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.buyFeatureBtn = new BuyFeatureBtn();
      this.gameView.addChild(
        this.buyFeatureBtn,
        new Backdrop(
          EventTypes.OPEN_BUY_FEATURE_POPUP_BG,
          EventTypes.CLOSE_BUY_FEATURE_POPUP_BG,
        ),
        new BuyFeaturePopup(),
        new BuyFeaturePopupConfirm(),
      );
    }

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.safeArea.addChild(this.gameView);
    this.safeArea.addChild(new FreeSpinsView());
    this.application.stage.addChild(this.safeArea);
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    }
    if (setBrokenBuyFeatureGame() !== '') {
      window.setTimeout(() => {
        eventManager.emit(
          EventTypes.START_BUY_FEATURE_ROUND,
          setBrokenBuyFeatureGame(),
        );
      }, 0);
    }
  }

  private onBrokenGame(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.REGULAR],
    });
    eventManager.emit(EventTypes.INITIALIZE_FREESPINS, setCurrentBonus()!.data);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    this.setState(SlotMachineState.IDLE);
  }

  private initListeners(): void {
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_FREESPINS,
      this.endFreeSpins.bind(this),
    );
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        // AudioHowl.play({ type: ISongs.XT001S_BigWin_Loop });
      }
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    AudioHowl.stop({ type: ISongs.XT001S_SpinStart_Loop });
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();

    const Reelset = setPrevReelSetId()
      ? this.slotConfig.reels.find((reel) => reel.id === setPrevReelSetId())
      : this.slotConfig.reels.find((reel) => reel.type === ReelSetType.DEFAULT);
    const spinResult = getSpinResult3x5({
      reelPositions: setPrevReelsPosition(),
      reelSet: Reelset!,
      icons: this.slotConfig.icons,
    });
    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      spinResult,
      setPrevDiaScore(),
    );
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    scatterScores?: number[];
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find(
        (reels) => reels.id === settings.reelSetId,
      );
      const spinResult = getSpinResult3x5({
        reelPositions: settings.reelPositions.slice(0, REELS_AMOUNT),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(
        EventTypes.SHOW_STOP_SLOTS_DISPLAY,
        spinResult,
        settings.scatterScores,
      );
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        setNextResult()!.balance.settled,
      );
      if (previousGameMode === GameMode.FREE_SPINS) {
        const userData = client.readQuery<{ user: IUserBalance }>({
          query: getUserGql,
        });
        client.writeQuery({
          query: getUserGql,
          data: {
            ...userData,
            user: {
              ...userData?.user,
              balance: setNextResult()!.balance.settled,
            },
          },
        });
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(
            setCurrency(),
            normalizeCoins(setCurrentFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          ),
        );
      }
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = setBrokenGame()
        ? setCurrentBonus()
        : this.getFreeSpinBonus();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );

      setCurrentBonus({ ...bonus, totalRounds: 0 });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          callback: () => {
            setCurrentBonus({
              ...bonus,
              isActive: true,
              totalRounds: setCurrentBonus().totalRounds,
              currentRound: 0,
            });
            if (AudioHowl.isRestricted) {
              BgmControl.handleChangeRestriction();
            }
            eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'reveal');
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        setCurrentBonus({
          ...bonus,
          isActive: true,
          totalRounds: 0,
          currentRound: 0,
        });
        this.setState(SlotMachineState.IDLE);
        eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'reveal');
      }
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: this.nextResult!.bet.result.reelPositions,
      reelSetId: reelSets[GameMode.REGULAR],
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
    eventManager.emit(EventTypes.INITIALIZE_FREESPINS, setCurrentBonus()!.data);
  }

  private async endFreeSpins(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      reelSetId: bet.data.bet.reelSetId,
    };

    const scatterScores = createScatterScoreArray(
      bet.data.bet.data.features.diamonds,
    );

    setPrevReelsPosition(reelPositions.slice(0, REELS_AMOUNT));
    setPrevDiaScore(scatterScores);
    setPrevReelSetId(reelSetId);

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        scatterScores,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions,
      });
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        totalWinAmount: setFreeSpinsTotalWin(),
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('freeSpinsTotalWin.result', {
          betAmount: formatNumber(
            setCurrency(),
            normalizeCoins(setBetAmount()),
            showCurrency(setCurrency()),
          ),
          totalAmount: setCurrentBonus().data.rewardAmount,
        }),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )}`,
        totalWinAmount: setFreeSpinsTotalWin(),
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('freeSpinsTotalWin.result', {
          betAmount: formatNumber(
            setCurrency(),
            normalizeCoins(setBetAmount()),
            showCurrency(setCurrency()),
          ),
          totalAmount: setCurrentBonus().data.rewardAmount,
        }),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total,
      false,
    );
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isFreeSpinsMode(setGameMode())) {
            this.updateFreeSpinsAmount(
              setCurrentBonus().currentRound,
              setCurrentBonus().rounds,
            );
          }
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.nextResult.bet.reelSet.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(
              this.nextResult.bet.result.spinResult,
            ),
            this.getScatterAnnounceType(this.nextResult.bet.reelSet.id),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION, isTurboSpin);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinsMode(setGameMode()) && !!isTurboSpin,
      );

      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        bonus.totalRounds += 1;
        setCurrentBonus(bonus);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation =
        reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(
                setCurrentBonus().currentRound,
                setCurrentBonus().rounds,
              );
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(
                this.nextResult.bet.result.spinResult,
              ),
              this.getAnticipationStartReelId(
                this.nextResult.bet.result.spinResult,
              ),
              this.getScatterAnnounceType(this.nextResult.bet.reelSet.id),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    if (freeSpinsBonus) {
      if (!isFreeSpinsMode(mode)) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        this.startFreeSpins();
        this.setState(SlotMachineState.IDLE);
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      }
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(
          setCurrentFreeSpinsTotalWin() +
            this.nextResult!.bet.result.winCoinAmount,
        );
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private dynamicReelSetChange(reelSetId: string): void {
    if (setReelSetId() !== reelSetId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSetId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
    setIsBuyFeatureSpin(false);
  }

  private getAnticipationStartReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;

    let minReelId = REELS_AMOUNT;
    // TODO
    const scatterCnt = this.getScatterCount(spinResult);
    if (
      scatterCnt.some(
        (col, index) => col >= ANTICIPATION_START_SCCNT && index <= 2,
      )
    ) {
      minReelId = ANTICIPATION_START_REELID;
    }
    return minReelId;
  }

  private getStopSoundSymbolCount(spinResult: Array<Icon>): Array<number> {
    const reelSnd = [0, 0, 0, 0, 0];
    const scatterCnt = this.getScatterCount(spinResult);
    let setcnt = 0;

    for (const col of SC_REELS) {
      if (scatterCnt[col] >= 1) {
        reelSnd[col] = ReelStopSndType.Scatter1 + setcnt;
        setcnt += 1;
      } else {
        break;
      }
    }
    return reelSnd;
  }

  private getScatterCount(spinResult: Array<Icon>): Array<number> {
    let count = 0;

    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private getScatterAnnounceType(reelSetId: string): number {
    if (isBuyFeatureMode()) return 0;

    const stopSoundSymbolNo = this.getStopSoundSymbolCount(
      this.nextResult!.bet.result.spinResult,
    );
    const rand = getRandomFromUUID(this.nextResult!.bet.id, 1000);
    return getResultFromTbl(
      LOTTBL_SC_ANNOUNCE[Math.max(...stopSoundSymbolNo)],
      rand,
    );
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult3x5({
      reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
      reelSet: setSlotConfig().reels.find(
        (reelSet) => reelSet.id === result.bet.reelSet.id,
      )!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(
      result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
    );
    setPrevReelSetId(result.bet.reelSet.id);
    this.nextResult = result;
    setNextResult(result);
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult.balance.placed,
      );
    }
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(
      this.nextResult!.bet.result.spinResult,
    );
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinsMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
        setIsFreeSpinsWin() ||
        setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      // if (isFreeSpinsMode(setGameMode())) {
      //   if (
      //     setCurrentBonus().isActive &&
      //     setCurrentBonus().rounds === setCurrentBonus().currentRound
      //   ) {
      //     setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      //     this.endFreeSpins();
      //   } else {
      //     this.skipAnimations();
      //     setTimeout(
      //       () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
      //       setCurrentBonus().currentRound === 0 ? 0 : 500,
      //     );
      //   }
      // }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus()) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.XT001S_FeatureTrigger].duration +
            JINGLE_TO_WIN_DURATION,
        );
        jingleDelay.addOnStart(() => {
          eventManager.emit(EventTypes.START_DIA_WIN_ANIMATION);
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else if (this.hasWin()) {
        const jingleDelay = Tween.createDelayAnimation(WIN_ANIM_START_DELAY);
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
