import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import ViewContainer from '../../components/container';
import {
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private base: PIXI.Graphics;

  constructor() {
    super();

    this.base = this.initBaseGraphic();
    this.addChild(this.base);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {}

  private initBaseGraphic(): PIXI.Graphics {
    const bg = new PIXI.Graphics()
      .beginFill(0x000000)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        (SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT) / 2,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    return bg;
  }
}

export default ReelsBackgroundContainer;
