import { AutoResizeTextStyle } from '../text/autoResizeText';

const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 35.5,
  fontFamily: font,
  align: 'center',
  lineJoin: 'round',
  fill: '#ff8e00',
  maxWidth: 1000,
};

export const youWonTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 60,
  fontFamily: font,
  lineJoin: 'round',
  fill: '#fff',
  maxWidth: 1024,
};

export const featureNameTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45.5,
  fontFamily: font,
  lineJoin: 'round',
  fill: '#ffd200',
  maxWidth: 1024,
};

export const descriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 35.5,
  fontFamily: font,
  fill: '0xffffff',
  lineJoin: 'round',
  maxWidth: 1024,
  align: 'center',
};

export const btnTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45.5,
  fontFamily: font,
  fill: '#fff',
  lineJoin: 'round',
  maxWidth: 1000,
};

export const totalWinTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fontSize: 60,
  fill: '#ffffff',
};

export const totalWinAmountTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fill: ['#fff700', '#ffec70', '#402b0c', '#c0a311', '#feee86', '#804614', '#ffd500'],
  fillGradientStops: [0.42, 0.65],
  fontSize: 80,
  fontWeight: 'bold',
};
