import { ISongs } from '../../config';
import { BASE_REEL_ROLLING_DURATION } from '../config';

type ScAnnounceType = {
  reelCnt: number;
  announceStartDelay: number[];
  reelStopDelay: number;
  snd: string[];
};
const SCANNOUNCE_SND_NML = [
  ISongs.XT001S_Anticipation_01,
  ISongs.XT001S_Anticipation_02,
  ISongs.XT001S_Anticipation_03,
];
export const ScAnnounceData: ScAnnounceType[] = [
  {
    reelCnt: 0,
    announceStartDelay: [1250, 3000, 6500],
    reelStopDelay: 0,
    snd: ['', '', ''],
  },
  {
    reelCnt: 1,
    announceStartDelay: [1250, 3000, 6500],
    reelStopDelay: 3000 - BASE_REEL_ROLLING_DURATION,
    snd: SCANNOUNCE_SND_NML,
  },
  {
    reelCnt: 2,
    announceStartDelay: [1250, 3000, 6500],
    reelStopDelay: 5500 - BASE_REEL_ROLLING_DURATION,
    snd: SCANNOUNCE_SND_NML,
  },
  {
    reelCnt: 3,
    announceStartDelay: [1250, 3000, 6500],
    reelStopDelay: 9000 - BASE_REEL_ROLLING_DURATION,
    snd: SCANNOUNCE_SND_NML,
  },
  {
    reelCnt: 3,
    announceStartDelay: [6500, 6500, 6500],
    reelStopDelay: 9000 - BASE_REEL_ROLLING_DURATION,
    snd: ['', '', ISongs.XT001S_Anticipation_SP],
  },
];

export const LOTTBL_SC_ANNOUNCE = [
  //non,Reel1,Reel13,Reel135
  [1000, 0, 0, 0], //SCNon
  [1000, 0, 0, 0], //SC1
  [710, 228, 62, 0], //SC13
  [467, 228, 228, 58, 19], //SC135
];

export const LOTS_SCATTER_ANNOUNCE_DENOM = 1000;

export const SC_ANNOUNCE_REEL_NUM = 3;
