import { Container } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { REELS_AMOUNT, SLOTS_PER_REEL_AMOUNT, eventManager } from '../config';

import { BingoLine, bingoLineIndex, bingoLineList, symbolSettings } from './config';
import { FreeSpinsSymbol } from './freeSpinsSymbol';

export class FreeSpinsSymbolContainer extends Container<FreeSpinsSymbol> {
  private earnedBingo: Set<BingoLine> = new Set();

  private multipliers: number[] = [];

  private symbols: FreeSpinsSymbol[];

  constructor() {
    super();
    this.sortableChildren = true;
    const symbols = symbolSettings.map((setting, i) => {
      const symbol = new FreeSpinsSymbol(i);
      symbol.scale.set(setting.scale);
      symbol.position.set(...setting.position);
      return symbol;
    });
    this.symbols = symbols;
    this.addChild(...symbols);
  }

  public initReel(result: number[], mp: number[]): void {
    this.earnedBingo.clear();
    this.multipliers = mp;
    result.forEach((value, i) => {
      this.symbols[i].value = value;
      this.symbols[i].mult = 0;
      this.symbols[i].loop();
    });
  }

  public startSpin(): void {
    const animations = new AnimationGroup();

    this.blankSymbolForEach((symbol) => {
      animations.addAnimation(symbol.startSpin());
    });
    AudioHowl.play({ type: ISongs.XT001S_SpinStart_2, stopPrev: true });
    animations.start();
  }

  public stopSpin(result: number[], lastSpin: boolean): void {
    const animations = new AnimationGroup();

    let delay = 0;
    let hasNewDia = false;

    this.blankSymbolForEach((symbol, index, remain) => {
      symbol.value = result[index];
      const hasLastAnticipation = !hasNewDia && lastSpin && remain === 1;
      const hasSwayAnticipation = !hasNewDia && lastSpin && remain <= 3;
      hasNewDia = hasNewDia || symbol.value > 0;

      const chain = new AnimationChain({});
      chain.appendAnimation(Tween.createDelayAnimation(delay));
      if (hasSwayAnticipation) {
        delay = delay + 2500;
      } else {
        delay = delay + 200;
      }
      chain.appendAnimation(symbol.stopSpin(hasLastAnticipation, hasSwayAnticipation));
      animations.addAnimation(chain);
      if (result[index] > 0) {
        chain.addOnComplete(() => {
          this.checkBingoLines();
        });
      }
    });
    animations.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'stop');
    });
    animations.start();
  }

  private checkBingoLines(): void {
    bingoLineList.forEach((bl) => {
      if (this.earnedBingo.has(bl.line)) return;

      if (bl.pos.every((i) => this.symbols[i].isDiaLooped)) {
        this.earnedBingo.add(bl.line);
        bl.pos.forEach((p) => (this.symbols[p].mult += this.multipliers[bingoLineIndex[bl.line]]));
        eventManager.emit(EventTypes.GET_BINGO, bl.line);
      }
    });
  }

  public startCollectAnimation(): Animation {
    const chain = new AnimationChain();
    let total = 0;
    this.diaSymbolForEach((dia, i) => {
      const delay = Tween.createDelayAnimation(1500);
      delay.addOnStart(() => {
        dia.zoom();
        if (dia.mult === 0) dia.mult = 1;
        total += dia.value * dia.mult;
        eventManager.emit(
          EventTypes.START_TRACK_ANIMATION,
          {
            x: dia.x,
            y: dia.y,
          },
          { x: 0, y: 454 },
          500,
          () => {
            eventManager.emit(EventTypes.SET_FREESPINS_RESULT, dia.value, dia.mult, total);
          },
        );
      });
      chain.appendAnimation(delay);
    });
    chain.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_FREESPINS_RESULT, 0, 0, setCurrentBonus().data.rewardAmount);
      eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'end');
    });
    chain.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'end');
    });
    chain.start();

    return chain;
  }

  /**
   * Traversal Sequence:
   *  1 4 7 10 13
   *  2 5 8 11 14
   *  3 6 9 12 15
   *
   * Actual Data Sequence:
   *   1  2  3  4  5
   *   6  7  8  9 10
   *  11 12 13 14 15
   */
  private blankSymbolForEach(cb: (symbol: FreeSpinsSymbol, index: number, remain: number) => void): void {
    let remain = this.symbols.filter((sym) => sym.value === 0).length;
    for (let col = 0; col < REELS_AMOUNT; col++) {
      for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
        const index = row * REELS_AMOUNT + col;
        const symbol = this.symbols[index];
        if (symbol.value === 0) {
          cb(symbol, index, remain);
          remain -= 1;
        }
      }
    }
  }

  private diaSymbolForEach(cb: (symbol: FreeSpinsSymbol, index: number) => void): void {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const symbol = this.symbols[index];
        if (symbol.value > 0) {
          cb(symbol, index);
        }
      }
    }
  }
}
