import * as PIXI from 'pixi.js';

import { EventTypes, Events } from '../../global.d';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class Backdrop extends PIXI.Sprite {
  private windowSize: PIXI.ISize;

  constructor(openOn?: keyof Events, closeOn?: keyof Events) {
    super(PIXI.Texture.WHITE);

    this.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    this.anchor.set(0.5);

    this.tint = 0x000000;
    this.alpha = 0.5;
    this.interactive = true;
    this.visible = false;

    this.windowSize = { width: 1, height: 1 };
    eventManager.addListener(EventTypes.RESIZE, (width, height) => {
      this.windowSize = { width, height };
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, (width, height, x, y, scale) => {
      this.width = this.windowSize.width / scale;
      this.height = this.windowSize.height / scale;
    });

    if (openOn) {
      eventManager.on(openOn, () => {
        this.visible = true;
      });
    }
    if (closeOn) {
      eventManager.on(closeOn, () => {
        this.visible = false;
      });
    }
  }
}
export default Backdrop;
