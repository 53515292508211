import { ITextStyle } from 'pixi.js';

import XText, { AutoResizeTextStyle } from '../text/autoResizeText';

const font = 'NotoSans-SemiCondensedBold';

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 70,
  fontFamily: font,
  fill: '#ff8e00',
  fontWeight: '500',
  maxWidth: 600,
};
export const buyFeatureConfirmTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 27.5,
  fontFamily: font,
  fill: '#ffffff',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

export const buyFeatureConfirmTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 33.5,
  fontFamily: font,
  fill: '#00d8ff',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 43.5,
  fontFamily: font,
  fill: '#ffffff',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 70,
  fontFamily: font,
  fill: '#ff8e00',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

export const betTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 48,
  fontFamily: font,
  fill: '#ffffff',
  fontWeight: '500',
  align: 'center',
  fontStyle: 'normal',
  maxWidth: 600,
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 47.5,
  fontFamily: font,
  fill: '#ff8e00',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

// Button Text Style
export const buyFeatureTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 36,
  lineJoin: 'round',
  fill: ['#fff700', '#ffec70', '#402b0c', '#c0a311', '#feee86', '#804614', '#ffd500'],
  fillGradientStops: [0.42, 0.65],
  stroke: '#000000',
  strokeThickness: 2,
  maxWidth: 170,
  maxHeight: 95,
};

export const buyFeatureHoverTextStyle: Partial<AutoResizeTextStyle> = {
  ...buyFeatureTextStyle,
  dropShadowColor: '#505050',
  fill: ['#ffff22', '#ffff90', '#604b2c', '#e0c331', '#fefeb6', '#a06634', '#fff520'],
};

export const buyFeatureClickTextStyle = buyFeatureHoverTextStyle;

export const buyFeatureDisableTextStyle: Partial<AutoResizeTextStyle> = {
  ...buyFeatureTextStyle,
  fill: [0x666666],
};
