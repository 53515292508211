import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { isFreeSpinsMode } from '../../utils';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private title: Spine;

  constructor() {
    super();

    this.title = new Spine(PIXI.Loader.shared.resources.Base_logo.spineData!);
    this.title.state.setAnimation(0, 'loop', true);

    this.addChild(this.title);
    this.position.set(SLOTS_CONTAINER_WIDTH / 2, -75);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }) {
    this.visible = !isFreeSpinsMode(settings.mode);
  }
}

export default GameTitle;
