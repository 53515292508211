import { IDestroyOptions } from '@pixi/display';
import { Emitter, EmitterConfigV3, LinkedListContainer } from '@pixi/particle-emitter';

export class ParticleEmitterContainer extends LinkedListContainer {
  protected emitter: Emitter;

  constructor(config: EmitterConfigV3) {
    super();

    this.emitter = new Emitter(this, config);
    this.emitter.autoUpdate = true;
  }

  public start(): void {
    this.emitter.emit = true;
  }

  public stop(cleanup = false): void {
    this.emitter.emit = false;
    if (cleanup) this.emitter.cleanup();
  }

  public destroy(options?: boolean | IDestroyOptions): void {
    this.emitter.destroy();
    super.destroy(options);
  }
}
