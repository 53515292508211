import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';

export function preTextureTransfer(pixiApp: PIXI.Application): void {
  const container = new PIXI.Container();

  for (const resource in PIXI.Loader.shared.resources) {
    if (PIXI.Loader.shared.resources[resource].texture) {
      const tex = PIXI.Loader.shared.resources[resource].texture;
      container.addChild(new PIXI.Sprite(tex));
    }
  }
  pixiApp.stage.addChild(container);
  pixiApp.renderer.once(EventTypes.POST_RENDER, () => {
    pixiApp.stage.removeChild(container);
  });
}
