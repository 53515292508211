import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { BgmSoundTypes, EventTypes } from '../../global.d';
import bgmControl from '../bgmControl/bgmControl';
import { eventManager } from '../config';

import { BingoLine, bingoLineIndex } from './config';

export class BingoEffects extends Spine {
  constructor() {
    super(Loader.shared.resources.Free_bingo.spineData!);

    eventManager.addListener(EventTypes.GET_BINGO, this.displayBingoLine.bind(this));
  }

  public displayBingoLine(bingoLine: BingoLine, playSfx = true) {
    if (playSfx) {
      if (bgmControl.currentBgmType !== BgmSoundTypes.FS2) {
        bgmControl.stopBgm();
        window.setTimeout(() => {
          if (bgmControl.currentBgmType !== BgmSoundTypes.FS2) bgmControl.playBgm(BgmSoundTypes.FS2);
        }, 1000);
      }
      AudioHowl.play({ type: ISongs.XT001S_Bingo, stopPrev: true });
    }
    this.state.setAnimation(bingoLineIndex[bingoLine], bingoLine, true);
  }

  public clearAllBingoLine() {
    this.state.setEmptyAnimations(0);
  }
}
