import { Event, TrackEntry } from '@pixi-spine/runtime-3.8';
import { IAnimationStateListener, Spine } from 'pixi-spine';
import { Loader, Ticker } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import { eventManager } from '../config';

export class FreeSpinsSymbol extends Spine {
  private _value: number;

  public isDiaLooped = false;

  public mult = 0;

  constructor(id: number) {
    super(Loader.shared.resources.Free_Sym_Diamond.spineData!);
    this.state.setAnimation(0, 'blank_loop', true);
    this._value = 0;

    this.state.addListener({
      start: (entry: TrackEntry) => {
        this.zIndex = entry.animation.name === 'zoom_dia' ? 10 + id : id;
      },
      event: (entry: TrackEntry, event: Event) => {
        if (event.data.audioPath.length > 0) AudioHowl.play({ type: event.data.name as ISongs, stopPrev: true });
        if (event.data.name === ISongs.XT001S_FS_Dia_Landing) {
          eventManager.emit(EventTypes.RESET_FREESPINS_REMAIN);
        }
      },
    });
  }

  public startSpin(): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation('blank_start')!.duration * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };
    animation.addOnStart(() => {
      this.state.setAnimation(0, 'blank_start', false);
      this.state.addListener(listener);
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
    });

    return animation;
  }

  public stopSpin(hasLastAnticipation: boolean, hasSwayAnticipation: boolean): Animation {
    const stopName = (() => {
      if (hasLastAnticipation) {
        return this.value > 0 ? 'stop_sway2_dia' : 'stop_sway2_blank';
      }

      if (hasSwayAnticipation) {
        return this.value > 0 ? 'stop_sway1_dia' : 'stop_sway1_blank';
      }

      return this.value > 0 ? 'Stop' : 'blank_stop';
    })();
    const loopName = this.value > 0 ? 'loop' : 'blank_loop';

    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation(stopName)!.duration * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        Ticker.shared.addOnce(() => {
          this.isDiaLooped = this.value > 0;
          animation.onComplete();
        });
      },
    };
    animation.addOnStart(() => {
      this.state.setAnimation(0, stopName, false);
      this.state.addAnimation(0, loopName, true, 0);
      this.state.addListener(listener);
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
      if (this.value > 0) eventManager.emit(EventTypes.RESET_FREESPINS_REMAIN);
    });

    return animation;
  }

  public loop(): void {
    this.isDiaLooped = this.value > 0;
    this.state.setAnimation(0, this.value > 0 ? 'loop' : 'blank_loop', true);
  }

  public zoom(): void {
    AudioHowl.play({ type: ISongs.XT001S_DiaCount, stopPrev: true });
    this.state.setAnimation(0, 'zoom_dia', false);
    this.state.addAnimation(0, 'loop', true, 0);
  }

  public get value(): number {
    return this._value;
  }

  public set value(value: number) {
    this._value = value;
    this.skeleton.setSkinByName(value ? `Stop_v${value}` : 'default');
  }
}
