import { IAddOptions, LoaderResource } from 'pixi.js';
import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.SC]: ResourceTypes.symbolSC,
  [SlotId.WL]: ResourceTypes.symbolWL,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.blurSymbolA,
  [SlotId.B]: ResourceTypes.blurSymbolB,
  [SlotId.C]: ResourceTypes.blurSymbolC,
  [SlotId.D]: ResourceTypes.blurSymbolD,
  [SlotId.E]: ResourceTypes.blurSymbolE,
  [SlotId.F]: ResourceTypes.blurSymbolF,
  [SlotId.G]: ResourceTypes.blurSymbolG,
  [SlotId.H]: ResourceTypes.blurSymbolH,
  [SlotId.SC]: ResourceTypes.blurSymbolSC,
  [SlotId.WL]: ResourceTypes.blurSymbolWL,
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.symbolA,
  ResourceTypes.symbolB,
  ResourceTypes.symbolC,
  ResourceTypes.symbolD,
  ResourceTypes.symbolE,
  ResourceTypes.symbolF,
  ResourceTypes.symbolG,
  ResourceTypes.symbolH,
  ResourceTypes.symbolSC,
  ResourceTypes.symbolWL,
  ResourceTypes.introBg,
  ResourceTypes.introPict01,
  ResourceTypes.introPict02,
  ResourceTypes.introPict03,
  ResourceTypes.buttonOk,
  ResourceTypes.messageBanner,
  ResourceTypes.blurSymbolA,
  ResourceTypes.blurSymbolB,
  ResourceTypes.blurSymbolC,
  ResourceTypes.blurSymbolD,
  ResourceTypes.blurSymbolE,
  ResourceTypes.blurSymbolF,
  ResourceTypes.blurSymbolG,
  ResourceTypes.blurSymbolH,
  ResourceTypes.blurSymbolWL,
  ResourceTypes.blurSymbolSC,
  ResourceTypes.blurSymbolSCBuyfeature,
  ResourceTypes.largeLine,
  ResourceTypes.buyFeatureBtnACancel,
  ResourceTypes.buyFeatureBtnACancelClick,
  ResourceTypes.buyFeatureBtnACancelDisable,
  ResourceTypes.buyFeatureBtnACancelOver,
  ResourceTypes.buyFeatureBtnAOk,
  ResourceTypes.buyFeatureBtnAOkClick,
  ResourceTypes.buyFeatureBtnAOkDisable,
  ResourceTypes.buyFeatureBtnAOkOver,
  ResourceTypes.buyFeatureBtnBM,
  ResourceTypes.buyFeatureBtnBMClick,
  ResourceTypes.buyFeatureBtnBMDisable,
  ResourceTypes.buyFeatureBtnBMOver,
  ResourceTypes.buyFeatureBtnBP,
  ResourceTypes.buyFeatureBtnBPClick,
  ResourceTypes.buyFeatureBtnBPDisable,
  ResourceTypes.buyFeatureBtnBPOver,
  ResourceTypes.buyFeatureMainBtn,
  ResourceTypes.buyFeatureMainBtnClick,
  ResourceTypes.buyFeatureMainBtnDisable,
  ResourceTypes.buyFeatureMainBtnOver,
  ResourceTypes.buyFeatureWakuA,
  ResourceTypes.buyFeatureWindow,
  ResourceTypes.buyFeatureWindowBlack,
  ResourceTypes.buyFeatureWindowConfirm,
  ResourceTypes.bonusFrame,
  ResourceTypes.freeSideMpX2,
  ResourceTypes.freeSideMpX3,
  ResourceTypes.freeSideMpX5,
  ResourceTypes.freeSideMpX10,
  ResourceTypes.freeSideMpX20,
  ResourceTypes.freeSideMpX30,
  ResourceTypes.freeSideMpX50,
  ResourceTypes.freeSideMpX100,
  ResourceTypes.freeBottomMpX2,
  ResourceTypes.freeBottomMpX3,
  ResourceTypes.freeBottomMpX5,
  ResourceTypes.freeBottomMpX10,
  ResourceTypes.freeBottomMpX20,
  ResourceTypes.freeBottomMpX30,
  ResourceTypes.freeBottomMpX50,
  ResourceTypes.freeBottomMpX100,
  ResourceTypes.freePartsImgRate,
  ResourceTypes.freePartsImgSpinframe,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/spine/merge.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
    {
      name: 'symbolA_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_Ruby.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolB_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_Sapphire.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolC_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_Emerald.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolD_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_Topaz.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolE_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_RA.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolF_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_RK.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolG_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_RQ.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolH_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_RJ.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolSC_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_Diamond.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbolWL_Animation',
      url: generateTexturePath('/animations', 'spine/Base_Sym_wild.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Base_logo',
      url: generateTexturePath('/animations', 'spine/Base_logo.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Base_frame',
      url: generateTexturePath('/animations', 'spine/Base_frame.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Base_background',
      url: generateTexturePath('/animations', 'spine/Base_bg.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Free_bg',
      url: generateTexturePath('/animations', 'spine/Free_bg.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Base_yokoku_Diamond',
      url: generateTexturePath('/animations', 'spine/Base_yokoku_Diamond.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Base_reach_frame',
      url: generateTexturePath('/animations', 'spine/Base_reach_frame.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Free_bingo',
      url: generateTexturePath('/animations', 'spine/Free_bingo.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Free_Sym_Diamond',
      url: generateTexturePath('/animations', 'spine/Free_Sym_Diamond.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Result',
      url: generateTexturePath('/animations', 'spine/Result.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Free_count',
      url: generateTexturePath('/animations', 'spine/Free_count.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources.spine_atlas!.data,
        imageNamePrefix: 'spine_',
      },
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
];

export const PRELOAD_SPINE_TEXTURES: IAddOptions[] = [
  'add_etc-0.jpg',
  'add_etc-1.jpg',
  'add_etc-2.jpg',
  'bg-0.jpg',
  'blue_J-0.png',
  'blue_J-1.png',
  'count-0.png',
  'gray_J-0.png',
  'gray_J-1.png',
  'gray_etc-0.png',
  'green_J-0.png',
  'green_J-1.png',
  'red_J-0.png',
  'red_J-1.png',
  'saku_sym-0.png',
  'saku_sym_add-0.jpg',
  'saku_sym_add-1.jpg',
  'saku_sym_add-2.jpg',
  'saku_sym_add-3.jpg',
  'saku_sym_add-4.jpg',
  'yellow_J-0.png',
  'yellow_J-1.png',
  'yellow_etc-0.png',
].map((v) => {
  return { name: `spine_${v}`, url: `/animations/desktop/spine/${v}` };
});
