import * as PIXI from 'pixi.js';

import { EventTypes, ISettledBet } from '../../global.d';
import {
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IWinLine } from '../d';
import TintContainer from '../tint/tintContainer';

import { LINE_BACK_TINT_ALPHA, LINE_BACK_TINT_COLOR } from './config';
import Line from './line';

class LinesContainer extends PIXI.Container {
  public linesData: number[][];

  public lines: Line[];

  constructor(linesData: number[][]) {
    super();

    this.sortableChildren = true;
    this.zIndex = 7;
    this.linesData = linesData;
    this.lines = [];
    for (let i = 0; i < this.linesData.length; i++) {
      const line = new Line(i, this.linesData[i]);
      this.lines.push(line);
      line.zIndex = 7;
      this.addChild(line);
    }

    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.showLines.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.hideLines.bind(this));
  }

  public showLines(lines: IWinLine[]): void {
    lines.forEach((line) => {
      if (line.lineId !== null) this.setLineVisibility(line.lineId, true);
    });
  }

  public hideLines(lines: IWinLine[]): void {
    if (!lines) {
      for (let i = 0; i < this.lines.length; i++) {
        this.setLineVisibility(i, false);
      }
    } else {
      lines.forEach((line) => {
        if (line.lineId !== null) this.setLineVisibility(line.lineId, false);
      });
    }
  }

  public setLineVisibility(id: number, visibility: boolean): void {
    this.lines[id].visible = visibility;
  }
}

export default LinesContainer;
