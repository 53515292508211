import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_HEIGHT } from '../config';

import { LINE_INTERPOLATION_DIST_X, LINE_INTERPOLATION_DIST_Y, LINE_INTERPOLATION_DOT_BORDER } from './config';

class Line extends PIXI.SimpleRope {
  constructor(id: number, data: number[]) {
    const points: PIXI.IPoint[] = [];

    const makeSlotPos = (pos: number): PIXI.Point => {
      return new PIXI.Point(
        (pos % REELS_AMOUNT) * REEL_WIDTH + REEL_WIDTH / 2,
        Math.floor(pos / REELS_AMOUNT) * SLOT_HEIGHT + SLOT_HEIGHT / 2,
      );
    };
    const normalizeVec = (vec: PIXI.Point): PIXI.Point => {
      const length = Math.hypot(vec.x, vec.y);
      const ret = new PIXI.Point(vec.x / length, vec.y / length);
      return ret;
    };
    const makeVec = (now: number, pre: number): PIXI.Point => {
      const posNow = makeSlotPos(now);
      const posPre = makeSlotPos(pre);

      const vec = new PIXI.Point(posNow.x - posPre.x, posNow.y - posPre.y);
      return normalizeVec(vec);
    };

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const pos = makeSlotPos(data[i]);
      //first or last is no interpolation
      if (i == 0 || i == REELS_AMOUNT - 1) {
        points.push(pos);
      } else {
        const vectorPre = makeVec(data[i], data[i - 1]);
        const vectorNext = makeVec(data[i + 1], data[i]);
        const dot = vectorPre.x * vectorNext.x + vectorPre.y * vectorNext.y;
        //an acute angle
        if (LINE_INTERPOLATION_DOT_BORDER >= dot) {
          for (let j = -1; j <= 1; j++) {
            const vec = j <= 0 ? vectorPre : vectorNext;
            points.push(
              new PIXI.Point(
                pos.x + vec.x * LINE_INTERPOLATION_DIST_X * j,
                pos.y + vec.y * LINE_INTERPOLATION_DIST_Y * j,
              ),
            );
          }
        } else {
          points.push(pos);
        }
      }
    }
    super(PIXI.Texture.from(ResourceTypes.largeLine), points);
    this.visible = false;
  }
}

export default Line;
