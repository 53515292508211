import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { TickerCallback } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../../config';
import Animation from '../../animations/animation';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import {
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  REELS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
} from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export const SCATTERNUM_SKINTBL = [
  'default',
  'Stop_v1',
  'Stop_v2',
  'Stop_v3',
  'Stop_v5',
  'Stop_v10',
  'Stop_v20',
  'Stop_v30',
  'Stop_v50',
  'Stop_v100',
];
export class ScatterAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private spine: Spine;

  private reelRow: number;

  public tickerCb: TickerCallback<void>;

  constructor(slotId: SlotId, num: number) {
    super();

    this.slotId = slotId;
    this.reelRow = 0;
    this.scale.set(SLOT_SCALE);

    this.spine = new Spine(PIXI.Loader.shared.resources[`${MAPPED_SYMBOLS[slotId]}_Animation`].spineData!);
    this.spine.state.setAnimation(0, 'Loop', true);
    this.spine.visible = true;

    const skinName = `Stop_v${num}`;
    this.spine.skeleton.setSkinByName(SCATTERNUM_SKINTBL.findIndex((v) => v === skinName) >= 0 ? skinName : 'default');

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {}, this, PIXI.UPDATE_PRIORITY.HIGH);
      },
    });

    this.addChild(this.spine);
    this.tickerCb = () => {
      this.scatterNumberVisibleCtrl();
    };
  }

  public startStopAnimation(): void {
    this.spine.state.setAnimation(0, 'Stop', false);
    this.spine.state.addAnimation(0, 'Loop', true, 0);
  }

  public startReachStopAnimation(): void {
    this.spine.state.setAnimation(0, 'SP_Stop', false);
    this.spine.state.addAnimation(0, 'SP_Loop', true, 0);
  }

  public endReachStopAnimation(): void {
    this.spine.state.addAnimation(0, 'Loop', true, 0);
  }

  public startWinAnimation(): void {
    this.spine.state.setAnimation(0, 'Win', false);
    this.spine.state.addAnimation(0, 'Win_Loop', true, 0);
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation('Win')?.duration ?? 0) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = 10;
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public getStartSpinAnimation(index: number, isTurboSpin?: boolean): Animation {
    const animation: AnimationGroup = new AnimationGroup({});

    const starting = new Tween({
      object: this.spine,
      property: TweenProperties.Y,
      propertyBeginValue: this.spine.y,
      target: this.spine.y + REEL_STARTING_SLOTS_AMOUNT * SLOT_HEIGHT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay:
        (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) *
        (index % REELS_AMOUNT),
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });

    this.reelRow = Math.floor(index / REELS_AMOUNT);

    animation.addAnimation(starting);
    animation.addOnStart(() => {
      this.spine.state.setAnimation(0, 'SetupPose_v1', false);
    });
    animation.addOnComplete(() => {
      this.spine.visible = false;
    });
    animation.addOnSkip(() => {
      this.spine.visible = false;
    });

    PIXI.Ticker.shared.add(this.tickerCb);
    return animation;
  }

  public skip(): void {}

  public set tint(value: number) {
    this.spine.tint = value;
  }

  private scatterNumberVisibleCtrl() {
    const REELDIST = [SLOT_HEIGHT * 2 - 80, SLOT_HEIGHT, 60];
    if (this.spine.y > REELDIST[this.reelRow]) {
      this.spine.visible = false;
      if (this.tickerCb) PIXI.Ticker.shared.remove(this.tickerCb);
    }
  }
}
export const isScatterAnimateSlot = (symbol: IAnimateSlot): symbol is ScatterAnimateSlot => {
  return symbol.slotId == SlotId.SC;
};
