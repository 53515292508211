import { upgradeConfig } from '@pixi/particle-emitter';
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { isFreeSpinsMode } from '../../utils';
import { ParticleEmitterContainer } from '../components/particles';
import { eventManager } from '../config';

import { bgParticleConfig } from './glitterConfig';

export type BgSkin = 'default' | 'freespins';

class Background extends PIXI.Container {
  private bgSpines: Record<BgSkin, Spine> = {
    default: new Spine(PIXI.Loader.shared.resources.Base_background.spineData!),
    freespins: new Spine(PIXI.Loader.shared.resources.Free_bg.spineData!),
  };

  private bgParticlesPermission: Record<BgSkin, boolean> = {
    default: true,
    freespins: false,
  };

  private currentSkin?: BgSkin;

  private glitterEffect: ParticleEmitterContainer;

  constructor(skin: BgSkin = 'default') {
    super();

    for (const key in this.bgSpines) {
      this.bgSpines[key as BgSkin].state.setAnimation(0, 'loop', true);
      this.addChild(this.bgSpines[key as BgSkin]);
    }

    const emitterConfig = upgradeConfig(
      bgParticleConfig,
      PIXI.Loader.shared.resources.Base_background.spineAtlas!.findRegion('efx_star_00000').texture,
    );

    this.glitterEffect = new ParticleEmitterContainer(emitterConfig);
    this.glitterEffect.zIndex = 10;
    this.glitterCtrl(skin);
    this.addChild(this.glitterEffect);

    this.setSkin(skin);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;
    for (const key in this.bgSpines) {
      this.bgSpines[key as BgSkin].visible = key === skinName;
    }
    this.glitterCtrl(skinName);
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const { mode } = settings;
    this.setSkin(isFreeSpinsMode(mode) ? 'freespins' : 'default');
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 1920, height: 1080 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / size.height;
    } else {
      scale = width / size.width;
    }
    this.bgSpines.default.scale.set(scale);
    this.bgSpines.freespins.scale.set(scale);
    this.glitterEffect.scale.set(scale);
  }

  private glitterCtrl(skin: BgSkin): void {
    if (this.bgParticlesPermission[skin]) {
      this.glitterEffect.start();
    } else {
      this.glitterEffect.stop();
    }
  }
}

export default Background;
