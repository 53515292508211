import _ from 'lodash';
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setNextResult } from '../../gql/cache';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_HEIGHT, SLOT_WIDTH, eventManager } from '../config';
import { Icon } from '../d';

import { LOTS_SCATTER_ANNOUNCE_DENOM, LOTTBL_SC_ANNOUNCE, SC_ANNOUNCE_REEL_NUM, ScAnnounceData } from './config';
import { getResultFromTbl } from './utils';

export class AnnouceContainer extends ViewContainer {
  public scAnimation: Animation[] = [];

  private slotSpine: Spine[] = [];

  constructor(spinResult: Icon[]) {
    super();
    for (let reel = 0; reel < SC_ANNOUNCE_REEL_NUM; reel++) {
      const spine = new Spine(PIXI.Loader.shared.resources.Base_yokoku_Diamond.spineData!);
      spine.x = REEL_WIDTH * 2 * reel + SLOT_WIDTH / 2; //Reel1,3,5
      spine.y = 0;
      spine.visible = false;
      this.addChild(spine);
      this.slotSpine.push(spine);
    }

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStoped.bind(this));
  }

  private setupScatterAnnounce(
    reelPositions: Array<number>,
    stopSoundSymbolNo: Array<number>,
    anticipationStartReelId: number,
    scAnnounceType: number,
  ): void {
    this.scAnimation = [];
    //update sprite y
    _(setNextResult()!.bet.result.spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .remove((col, index) => !(index % 2))
      .value()
      .forEach((reel, col) => {
        reel.forEach((slot, pos) => {
          if (slot.id === SlotId.SC) {
            this.slotSpine[col].y = SLOT_HEIGHT * pos + SLOT_HEIGHT / 2;
          }
        });
      });

    for (let reel = 0; reel < ScAnnounceData[scAnnounceType].reelCnt; reel++) {
      const delayAnim = Tween.createDelayAnimation(ScAnnounceData[scAnnounceType].announceStartDelay[reel]);
      delayAnim.addOnComplete(() => {
        this.slotSpine[reel].visible = true;
        this.slotSpine[reel].state.setAnimation(0, 'in', false);
        this.slotSpine[reel].state.addAnimation(0, 'loop', true, 0);

        if (ScAnnounceData[scAnnounceType].snd[reel] != '') {
          AudioHowl.play({
            type: ScAnnounceData[scAnnounceType].snd[reel],
            stopPrev: true,
          });
        }
      });
      this.scAnimation.push(delayAnim);
    }
    this.scAnimation.forEach((animation, index) => {
      animation?.start();
    });
  }

  private lotsScatterAnnounce(stopScType: number): number {
    const rand = Math.floor(Math.random() * LOTS_SCATTER_ANNOUNCE_DENOM);
    return getResultFromTbl(LOTTBL_SC_ANNOUNCE[stopScType], rand);
  }

  private reelStoped(reelId: number, stopSoundSymbolNo: number): void {
    this.scAnimation.forEach((animation, index) => {
      // *2 = annoucereel to reelId
      if (reelId! === index * 2) {
        animation.skip();
        this.slotSpine[index].visible = false;
      }
    });
  }

  private hideContainer(): void {
    this.scAnimation.forEach((animation) => {
      animation.skip();
    });
    this.slotSpine.forEach((spine) => {
      spine.visible = false;
    });
    this.scAnimation = [];
  }
}
