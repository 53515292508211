import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.introPict01,
    txtKeyTop: 'introTitle.page1.Top',
    txtKeyBottom: 'introTitle.page1.Bottom',
  },
  {
    img: ResourceTypes.introPict02,
    txtKeyTop: 'introTitle.page2.Top',
    txtKeyBottom: 'introTitle.page2.Bottom',
  },
  {
    img: ResourceTypes.introPict03,
    txtKeyTop: 'introTitle.page3.Top',
    txtKeyBottom: 'introTitle.page3.Bottom',
  },
];
